.addressess__drop {
  width: 100% !important;

  outline: none;

  font-size: 16px;
  padding: 0 4px 6px 0;

  border: 0;
  color: var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
}

.product__cardAfter {
  position: absolute;
  inset: 0;
}

.card__counter {
  width: 40px;
}

.counter__number {
  user-select: none;
}

.card__counter:hover {
  width: 110px;
}

.input__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    border-bottom: 1px solid var(--white-color, #FFF);
    color: var(--white-color, #FFF);
}

.input__field {
    padding: 10px 0;
    width: 100%;
    color: var(--white-color, #FFF);
    font-family: Raleway;
    font-size: 16px;
    border: 0;
    outline: none;
    background-color: transparent;
}

.input__field::placeholder {
    color: var(--white-color, #FFF);
}

.input__field__dark {
    /* padding: 10px 0;
    width: 100%;
    font-family: Raleway;
    font-size: 16px;
    border: 0;
    outline: none;
    background-color: transparent; */
    color: var(--secondary-color, #000) ;
    border-bottom: 1px solid var(--secondary-color, #000) ;
}

.input__field__dark::placeholder {
    color: var(--secondary-color, #000);
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.error__messageInputField {
    font-size: 10px;
}
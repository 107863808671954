.tab {
    z-index: 1;
    background-color: #f3f3f3;

    .tab__number {
        color: var(--dark-color);
        font-size: 32px;
    }
}

.active__tab {
    z-index: 0;
    background-color: var(--green-color);

    .tab__number,
    .tab__name {
        color: #FFF;
    }
}

.active__tab::after {
    position: absolute;
    content: '';

    z-index: 0;
    height: 100%;
    width: 60px;
    transform: rotate(-45deg);
    background-color: #FFF;
    left: -30px;
}
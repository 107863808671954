/* ====== Google Fonts ====== */
/* ====== Google Fonts ====== */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



/* ====== Selection ====== */
/* ====== Selection ====== */

::selection {
    background-color: var(--green-color);
    color: var(--white-color);
}



/* ====== Webkit Scroll bar ====== */
/* ====== Webkit Scroll bar ====== */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--green-color);
}




/* ====== Universal Reset ====== */
/* ====== Universal Reset ====== */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;

    font-family: 'Poppins', sans-serif;
}

.rotating__loader {
    animation: rotating360 2s infinite linear;
}

@keyframes rotating360 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.drop:hover {
    visibility: hidden;

    >.dropdown {
        opacity: 1;
        z-index: 1;
    }
}

.drop {
    top: 100%;
    visibility: visible;

    >.dropdown:hover {

        >.position-rel {
            >.dropdown {
                opacity: 1;
                z-index: 1;
            }
        }
    }
}


/*  ====== Variables ====== */
/*  ====== Variables ====== */

:root {
    --green-color: #00502d;
    --pink-color: #fd5f5c;
    --coffee-color: #f79823;
    --light-color: #eaeff4;
    --dark-color: #333;
    --white-color: #fff;
    --red-color: rgb(148, 0, 0);
}

.red-color {
    color: var(--red-color);
}

.dark-color {
    color: var(--dark-color);
}

.white-color {
    color: #FFF;
}

.green-color {
    color: var(--green-color);
}

.pink-color {
    color: var(--pink-color);
}

.coffee-color {
    color: var(--coffee-color);
}

.light-color {
    color: var(--light-color);
}

.green-back {
    background-color: var(--green-color);
}

.pink-back {
    background-color: var(--pink-color);
}

.coffee-back {
    background-color: var(--coffee-color);
}

.dark-back {
    background-color: var(--dark-color);
}

.light-back {
    background-color: var(--light-color);
}

.white-back {
    background-color: var(--white-color);
}


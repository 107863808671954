.detail__popup {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 70%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #FFF;

    z-index: 1;

    border-radius: 10px;
    /* box-shadow: 0px 4px 72px 0px rgba(38, 50, 56, 0.10); */
    box-shadow: 0px 4px 28px 0px rgba(38, 50, 56, 0.10);



    .heading {
        color: var(--text-color, #18303E);
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .popup__content {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 24px;

        width: 100%;

        .popup__container:first-child {
            grid-column: 1/4;
        }

        .popup__container:nth-child(2) {
            grid-column: 4/6;
        }

        .popup__container:last-child {
            grid-column: 1/6;
        }

        .popup__container {
            display: flex;
            flex-direction: column;
            gap: 6px;

            padding: 40px 20px 20px 20px;

            position: relative;

            border-radius: 10px;
            background: #FFF;

            .container__indicator {
                display: flex;
                align-items: center;
                gap: 8px;
                width: fit-content;

                top: -12px;
                left: -12px;
                position: absolute;

                padding: 8px 34px 8px 8px;

                background: #FFF;
                border-radius: 4px 27px 27px 4px;
                box-shadow: 0px 4px 17px 0px rgba(38, 50, 56, 0.10);
            }

            .container__row {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                .product__left {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    >img {
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                    }
                }

            }

            .product__div {
                display: flex;
                flex-direction: column;
                gap: 6px;

                .accessory {
                    width: auto;
                    margin-left: 4vw;
                }
            }
        }

        .popup__containerUpTo4 {
            max-height: 200px;
            overflow-y: scroll;
        }
    }

    .normal__text {
        color: var(--text-color, #18303E);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .dark__text {
        color: var(--text-color, #18303E);
        text-align: right;
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

@media (max-width:750px) {
    .detail__popup{
        width: 90%;
        
        .popup__content{
            display: flex;
            flex-direction: column;

            .popup__containerUpTo4{
                max-height: 140px;
            }
        }
    }
}
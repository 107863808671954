.hover-green:hover {
    color: var(--green-color) !important;
}

.hover-pink:hover {
    color: var(--pink-color) !important;
}

.hover-coffee:hover {
    color: var(--coffee-color) !important;
}

.hover-light:hover {
    color: var(--light-color) !important;
}

.hover-white:hover {
    color: var(--white-color) !important;
}

.hover-light-back:hover {
    background-color: var(--light-color) !important;
}

.hover-green-back:hover {
    background-color: var(--green-color) !important;
}

.hover-fw400:hover {
    font-weight: 400 !important;
}

.hover-fw500:hover {
    font-weight: 500 !important;
}

.hover-fw600:hover {
    font-weight: 600 !important;
}

.hover-fw700:hover {
    font-weight: 700 !important;
}


.hover-pink-back:hover {
    background-color: var(--pink-color) !important;
}

.hover-coffee-back:hover {
    background-color: var(--coffee-color) !important;
}

/* ====== Font Family Classes ====== */
/* ====== Font Family Classes ====== */

.family-poppins {
    font-family: 'Poppins', sans-serif !important;
}

.family-raleway {
    font-family: 'Raleway', sans-serif !important;
}



/* ====== Utilities ====== */
/* ====== Utilities ====== */

.d-flex {
    display: flex !important;
}

.flex-col {
    flex-direction: column !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.align-center {
    align-items: center !important;
}

.align-start {
    align-items: flex-start !important;
}

.align-end {
    align-items: flex-end !important;
}

.space-between {
    justify-content: space-between !important;
}

.justify-center {
    justify-content: center !important;
}

.justify-end {
    justify-content: flex-end !important;
}

.text-center {
    text-align: center !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.mt-5 {
    margin-top: 50px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.ml-3 {
    margin-left: 30px !important;
}

.ml-4 {
    margin-left: 40px !important;
}

.ml-5 {
    margin-left: 50px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.mr-2 {
    margin-right: 20px !important;
}

.mr-3 {
    margin-right: 30px !important;
}

.mr-4 {
    margin-right: 40px !important;
}

.mr-5 {
    margin-right: 50px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-4 {
    margin-bottom: 40px !important;
}

.mb-5 {
    margin-bottom: 50px !important;
}

.p-2 {
    padding: 2px !important;
}

.p-4 {
    padding: 4px !important;
}

.p-6 {
    padding: 6px !important;
}

.p-8 {
    padding: 8px !important;
}

.p-10 {
    padding: 10px !important;
}

.p-12 {
    padding: 12px !important;
}

.p-16 {
    padding: 16px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-24 {
    padding: 24px !important;
}

.p-28 {
    padding: 28px !important;
}

.p-32 {
    padding: 32px !important;
}

.p-36 {
    padding: 36px !important;
}

.p-40 {
    padding: 40px !important;
}

.p-44 {
    padding: 44px !important;
}

.p-2-0 {
    padding: 2px 0 !important;
}

.p-0-2 {
    padding: 0 2px !important;
}

.p-4-0 {
    padding: 4px 0 !important;
}

.p-2-4 {
    padding: 2px 4px !important;
}

.p-4-2 {
    padding: 4px 2px !important;
}

.p-6-4 {
    padding: 6px 4px !important;
}

.p-4-6 {
    padding: 4px 6px !important;
}

.p-8-4 {
    padding: 8px 4px !important;
}

.p-4-8 {
    padding: 4px 8px !important;
}

.p-10-4 {
    padding: 10px 4px !important;
}

.p-4-10 {
    padding: 4px 10px !important;
}

.p-12-4 {
    padding: 12px 4px !important;
}

.p-4-12 {
    padding: 4px 12px !important;
}

.p-16-4 {
    padding: 16px 4px !important;
}

.p-0-4 {
    padding: 0 4px !important;
}

.p-6-0 {
    padding: 6px 0 !important;
}

.p-2-6 {
    padding: 2px 6px !important;
}

.p-6-2 {
    padding: 6px 2px !important;
}

.p-8-6 {
    padding: 8px 6px !important;
}

.p-6-8 {
    padding: 6px 8px !important;
}

.p-8-6 {
    padding: 8px 6px !important;
}

.p-10-6 {
    padding: 10px 6px !important;
}

.p-6-10 {
    padding: 6px 10px !important;
}

.p-12-6 {
    padding: 12px 6px !important;
}

.p-6-12 {
    padding: 6px 12px !important;
}

.p-16-6 {
    padding: 16px 6px !important;
}

.p-6-16 {
    padding: 6px 16px !important;
}

.p-6-0 {
    padding: 6px 0 !important;
}

.p-6-2 {
    padding: 6px 2px !important;
}

.p-6-4 {
    padding: 6px 4px !important;
}

.p-6-6 {
    padding: 6px 6px !important;
}

.p-6-8 {
    padding: 6px 8px !important;
}

.p-6-10 {
    padding: 6px 10px !important;
}

.p-6-12 {
    padding: 6px 12px !important;
}



.p-0-6 {
    padding: 0 6px !important;
}

.p-8-0 {
    padding: 8px 0 !important;
}

.p-2-8 {
    padding: 2px 8px !important;
}

.p-8-2 {
    padding: 8px 2px !important;
}

.p-8-4 {
    padding: 8px 4px !important;
}

.p-4-8 {
    padding: 4px 8px !important;
}

.p-10-8 {
    padding: 10px 8px !important;
}

.p-8-10 {
    padding: 8px 10px !important;
}

.p-12-8 {
    padding: 12px 8px !important;
}

.p-8-12 {
    padding: 8px 12px !important;
}

.p-16-8 {
    padding: 16px 8px !important;
}

.p-8-16 {
    padding: 8px 16px !important;
}

.p-0-8 {
    padding: 0 8px !important;
}

.p-10-0 {
    padding: 10px 0 !important;
}

.p-10-16 {
    padding: 10px 16px !important;
}

.p-0-10 {
    padding: 0 10px !important;
}

.p-12-0 {
    padding: 12px 0 !important;
}

.p-0-12 {
    padding: 0 12px !important;
}

.p-16-0 {
    padding: 16px 0 !important;
}

.p-0-16 {
    padding: 0 16px !important;
}

.p-20-0 {
    padding: 20px 0 !important;
}

.p-0-20 {
    padding: 0 20px !important;
}

.p-24-0 {
    padding: 24px 0 !important;
}

.p-0-24 {
    padding: 0 24px !important;
}

.p-28-0 {
    padding: 28px 0 !important;
}

.p-0-28 {
    padding: 0 28px !important;
}

.p-32-0 {
    padding: 32px 0 !important;
}

.p-0-32 {
    padding: 0 32px !important;
}

.p-36-0 {
    padding: 36px 0 !important;
}

.p-0-36 {
    padding: 0 36px !important;
}

.p-40-0 {
    padding: 40px 0 !important;
}

.p-0-40 {
    padding: 0 40px !important;
}

.p-44-0 {
    padding: 44px 0 !important;
}

.p-0-44 {
    padding: 0 44px !important;
}

.p-48-0 {
    padding: 48px 0 !important;
}

.p-0-48 {
    padding: 0 48px !important;
}

.p-52-0 {
    padding: 52px 0 !important;
}

.p-0-52 {
    padding: 0 52px !important;
}

.p-56-0 {
    padding: 56px 0 !important;
}

.p-0-56 {
    padding: 0 56px !important;
}

.p-60-0 {
    padding: 60px 0 !important;
}

.p-0-60 {
    padding: 0 60px !important;
}

.p-64-0 {
    padding: 64px 0 !important;
}

.p-0-64 {
    padding: 0 64px !important;
}

.p-68-0 {
    padding: 68px 0 !important;
}

.p-0-68 {
    padding: 0 68px !important;
}

.p-72-0 {
    padding: 72px 0 !important;
}

.p-0-72 {
    padding: 0 72px !important;
}

.pt-1 {
    padding-top: 10px !important;
}

.pt-2 {
    padding-top: 20px !important;
}

.pt-3 {
    padding-top: 30px !important;
}

.pt-4 {
    padding-top: 40px !important;
}

.pt-5 {
    padding-top: 50px !important;
}

.pl-1 {
    padding-left: 10px !important;
}

.pl-2 {
    padding-left: 20px !important;
}

.pl-3 {
    padding-left: 30px !important;
}

.pl-4 {
    padding-left: 40px !important;
}

.pl-5 {
    padding-left: 50px !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pr-2 {
    padding-right: 20px !important;
}

.pr-3 {
    padding-right: 30px !important;
}

.pr-4 {
    padding-right: 40px !important;
}

.pr-5 {
    padding-right: 50px !important;
}

.pb-1 {
    padding-bottom: 10px !important;
}

.pb-2 {
    padding-bottom: 20px !important;
}

.pb-3 {
    padding-bottom: 30px !important;
}

.pb-4 {
    padding-bottom: 40px !important;
}

.pb-5 {
    padding-bottom: 50px !important;
}

.pb-6 {
    padding-bottom: 60px !important;
}

.pb-7 {
    padding-bottom: 70px !important;
}

.pb-8 {
    padding-bottom: 80px !important;
}

.pb-9 {
    padding-bottom: 90px !important;
}

.fs-8 {
    font-size: 8px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-26 {
    font-size: 26px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-36 {
    font-size: 36px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-48 {
    font-size: 48px !important;
}

.fs-56 {
    font-size: 56px !important;
}

.fs-64 {
    font-size: 64px !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.gap-1 {
    gap: 1px !important;
}

.gap-2 {
    gap: 2px !important;
}

.gap-4 {
    gap: 4px !important;
}

.gap-8 {
    gap: 8px !important;
}

.gap-12 {
    gap: 12px !important;
}

.gap-16 {
    gap: 16px !important;
}

.gap-20 {
    gap: 20px !important;
}

.gap-24 {
    gap: 24px !important;
}

.gap-32 {
    gap: 32px !important;
}

.gap-40 {
    gap: 40px !important;
}

.gap-48 {
    gap: 48px !important;
}

.gap-56 {
    gap: 56px !important;
}

.gap-64 {
    gap: 64px !important;
}

.gap-80 {
    gap: 80px !important;
}

.gap-96 {
    gap: 96px !important;
}

.gap-128 {
    gap: 128px !important;
}

.gap-r-1 {
    row-gap: 1px !important;
}

.gap-r-2 {
    row-gap: 2px !important;
}

.gap-r-4 {
    row-gap: 4px !important;
}

.gap-r-8 {
    row-gap: 8px !important;
}

.gap-r-12 {
    row-gap: 12px !important;
}

.gap-r-16 {
    row-gap: 16px !important;
}

.gap-r-20 {
    row-gap: 20px !important;
}

.gap-r-24 {
    row-gap: 24px !important;
}

.gap-r-32 {
    row-gap: 32px !important;
}

.gap-r-40 {
    row-gap: 40px !important;
}

.gap-r-48 {
    row-gap: 48px !important;
}

.gap-r-56 {
    row-gap: 56px !important;
}

.gap-r-64 {
    row-gap: 64px !important;
}

.gap-r-80 {
    row-gap: 80px !important;
}

.gap-r-96 {
    row-gap: 96px !important;
}

.gap-r-128 {
    row-gap: 128px !important;
}

.max-inner {
    max-width: 1240px !important;
}

.w-full {
    width: 100% !important;
}

.h-full {
    height: 100% !important;
}

.w-fit {
    width: fit-content !important;
}

.h-fit {
    height: fit-content !important;
}

.border-0 {
    border: 0 !important;
}

.border-grey {
    border: 1px solid #bdbdbd !important;
}

.border-green {
    border: 1px solid var(--green-color) !important;
}

.border-t-1-grey {
    border-top: 1px solid #bdbdbd !important;
}

.border-b-1-grey {
    border-bottom: 1px solid #bdbdbd !important;
}

.border-b-1-green {
    border-bottom: 1px solid var(--green-color) !important;
}

.border-b-2-green {
    border-bottom: 2px solid var(--green-color) !important;
}

.border-b-3-green {
    border-bottom: 3px solid var(--green-color) !important;
}

.border-b-4-green {
    border-bottom: 4px solid var(--green-color) !important;
}

.border-b-5-green {
    border-bottom: 5px solid var(--green-color) !important;
}

.border-b-6-green {
    border-bottom: 6px solid var(--green-color) !important;
}

.border-l-1-green {
    border-left: 1px solid var(--green-color) !important;
}

.border-r-1-green {
    border-right: 1px solid var(--green-color) !important;
}

.border-r-1-grey {
    border-right: 1px solid #bdbdbd !important;
}

.border-r-1-white {
    border-right: 1px solid #fff !important;
}

.border-l-1-white {
    border-left: 1px solid #fff !important;
}

.border-t-1-green {
    border-top: 1px solid var(--green-color) !important;
}

.border-1-green {
    border: 1px solid var(--green-color) !important;
}

.border-1-white {
    border: 1px solid #fff !important;
}

.w-2 {
    width: 2px !important;
}

.w-4 {
    width: 4px !important;
}

.w-6 {
    width: 6px !important;
}

.w-8 {
    width: 8px !important;
}

.w-10 {
    width: 10px !important;
}

.w-12 {
    width: 12px !important;
}

.w-14 {
    width: 14px !important;
}

.w-16 {
    width: 16px !important;
}

.w-18 {
    width: 18px !important;
}

.w-20 {
    width: 20px !important;
}

.w-22 {
    width: 22px !important;
}

.w-24 {
    width: 24px !important;
}

.w-26 {
    width: 26px !important;
}

.w-28 {
    width: 28px !important;
}

.w-30 {
    width: 30px !important;
}

.w-32 {
    width: 32px !important;
}

.w-34 {
    width: 34px !important;
}

.w-36 {
    width: 36px !important;
}

.w-38 {
    width: 38px !important;
}

.w-40 {
    width: 40px !important;
}

.w-50 {
    width: 50px !important;
}

.w-60 {
    width: 60px !important;
}

.w-70 {
    width: 70px !important;
}

.w-80 {
    width: 80px !important;
}

.w-90 {
    width: 90px !important;
}

.w-100 {
    width: 100px !important;
}

.w-110 {
    width: 110px !important;
}

.w-120 {
    width: 120px !important;
}

.w-130 {
    width: 130px !important;
}

.w-140 {
    width: 140px !important;
}

.w-150 {
    width: 150px !important;
}

.w-160 {
    width: 160px !important;
}

.w-170 {
    width: 170px !important;
}

.w-180 {
    width: 180px !important;
}

.w-190 {
    width: 190px !important;
}

.w-200 {
    width: 200px !important;
}

.w-210 {
    width: 210px !important;
}

.w-220 {
    width: 220px !important;
}

.w-230 {
    width: 230px !important;
}

.w-240 {
    width: 240px !important;
}

.w-250 {
    width: 250px !important;
}

.w-260 {
    width: 260px !important;
}

.w-270 {
    width: 270px !important;
}

.w-280 {
    width: 280px !important;
}

.w-290 {
    width: 290px !important;
}

.w-300 {
    width: 300px !important;
}

.w-310 {
    width: 310px !important;
}

.w-320 {
    width: 320px !important;
}

.w-330 {
    width: 330px !important;
}

.w-340 {
    width: 340px !important;
}

.w-350 {
    width: 350px !important;
}

.w-360 {
    width: 360px !important;
}

.w-370 {
    width: 370px !important;
}

.w-380 {
    width: 380px !important;
}

.w-390 {
    width: 390px !important;
}

.w-400 {
    width: 400px !important;
}

.w-410 {
    width: 410px !important;
}

.w-420 {
    width: 420px !important;
}

.w-430 {
    width: 430px !important;
}

.w-440 {
    width: 440px !important;
}

.w-450 {
    width: 450px !important;
}

.w-460 {
    width: 460px !important;
}

.w-470 {
    width: 470px !important;
}

.w-480 {
    width: 480px !important;
}

.w-490 {
    width: 490px !important;
}

.w-500 {
    width: 500px !important;
}

.w-510 {
    width: 510px !important;
}

.w-520 {
    width: 520px !important;
}

.w-530 {
    width: 530px !important;
}

.w-540 {
    width: 540px !important;
}

.w-550 {
    width: 550px !important;
}

.w-560 {
    width: 560px !important;
}

.w-570 {
    width: 570px !important;
}

.w-580 {
    width: 580px !important;
}

.w-590 {
    width: 590px !important;
}

.w-600 {
    width: 600px !important;
}

.w-610 {
    width: 610px !important;
}

.w-620 {
    width: 620px !important;
}

.w-630 {
    width: 630px !important;
}

.w-640 {
    width: 640px !important;
}

.w-650 {
    width: 650px !important;
}

.w-660 {
    width: 660px !important;
}

.w-670 {
    width: 670px !important;
}

.w-680 {
    width: 680px !important;
}

.w-690 {
    width: 690px !important;
}

.w-890 {
    width: 690px !important;
}

.w-700 {
    width: 700px !important;
}

.w-710 {
    width: 710px !important;
}

.w-720 {
    width: 720px !important;
}

.w-730 {
    width: 730px !important;
}

.w-740 {
    width: 740px !important;
}

.w-750 {
    width: 750px !important;
}

.w-760 {
    width: 760px !important;
}

.w-770 {
    width: 770px !important;
}

.w-780 {
    width: 780px !important;
}

.w-790 {
    width: 790px !important;
}

.w-800 {
    width: 800px !important;
}

.w-810 {
    width: 810px !important;
}

.w-820 {
    width: 820px !important;
}

.w-830 {
    width: 830px !important;
}

.w-840 {
    width: 840px !important;
}

.w-850 {
    width: 850px !important;
}

.w-860 {
    width: 860px !important;
}

.w-870 {
    width: 870px !important;
}

.w-880 {
    width: 880px !important;
}

.w-890 {
    width: 890px !important;
}

.w-900 {
    width: 900px !important;
}

.min-w-2 {
    min-width: 2px !important;
}

.max-w-2 {
    max-width: 2px !important;
}

.min-w-4 {
    min-width: 4px !important;
}

.max-w-4 {
    max-width: 4px !important;
}

.min-w-6 {
    min-width: 6px !important;
}

.max-w-6 {
    max-width: 6px !important;
}

.min-w-8 {
    min-width: 8px !important;
}

.max-w-8 {
    max-width: 8px !important;
}

.min-w-10 {
    min-width: 10px !important;
}

.max-w-10 {
    max-width: 10px !important;
}

.min-w-12 {
    min-width: 12px !important;
}

.max-w-12 {
    max-width: 12px !important;
}

.min-w-14 {
    min-width: 14px !important;
}

.max-w-14 {
    max-width: 14px !important;
}

.min-w-16 {
    min-width: 16px !important;
}

.max-w-16 {
    max-width: 16px !important;
}

.min-w-18 {
    min-width: 18px !important;
}

.max-w-18 {
    max-width: 18px !important;
}

.min-w-20 {
    min-width: 20px !important;
}

.max-w-20 {
    max-width: 20px !important;
}

.min-w-22 {
    min-width: 22px !important;
}

.max-w-22 {
    max-width: 22px !important;
}

.min-w-24 {
    min-width: 24px !important;
}

.max-w-24 {
    max-width: 24px !important;
}

.min-w-26 {
    min-width: 26px !important;
}

.max-w-26 {
    max-width: 26px !important;
}

.min-w-28 {
    min-width: 28px !important;
}

.max-w-28 {
    max-width: 28px !important;
}

.min-w-30 {
    min-width: 30px !important;
}

.max-w-30 {
    max-width: 30px !important;
}

.min-w-32 {
    min-width: 32px !important;
}

.max-w-32 {
    max-width: 32px !important;
}

.min-w-34 {
    min-width: 34px !important;
}

.max-w-34 {
    max-width: 34px !important;
}

.min-w-36 {
    min-width: 36px !important;
}

.max-w-36 {
    max-width: 36px !important;
}

.min-w-38 {
    min-width: 38px !important;
}

.max-w-38 {
    max-width: 38px !important;
}

.min-w-40 {
    min-width: 40px !important;
}

.max-w-40 {
    max-width: 40px !important;
}

.min-w-42 {
    min-width: 42px !important;
}

.max-w-42 {
    max-width: 42px !important;
}

.min-w-44 {
    min-width: 44px !important;
}

.max-w-44 {
    max-width: 44px !important;
}

.min-w-46 {
    min-width: 46px !important;
}

.max-w-46 {
    max-width: 46px !important;
}

.min-w-48 {
    min-width: 48px !important;
}

.max-w-48 {
    max-width: 48px !important;
}

.min-w-50 {
    min-width: 50px !important;
}

.max-w-50 {
    max-width: 50px !important;
}

.min-w-52 {
    min-width: 52px !important;
}

.max-w-52 {
    max-width: 52px !important;
}

.min-w-54 {
    min-width: 54px !important;
}

.max-w-54 {
    max-width: 54px !important;
}

.min-w-56 {
    min-width: 56px !important;
}

.max-w-56 {
    max-width: 56px !important;
}

.min-w-58 {
    min-width: 58px !important;
}

.max-w-58 {
    max-width: 58px !important;
}

.min-w-60 {
    min-width: 60px !important;
}

.max-w-60 {
    max-width: 60px !important;
}

.min-w-62 {
    min-width: 62px !important;
}

.max-w-62 {
    max-width: 62px !important;
}

.min-w-64 {
    min-width: 64px !important;
}

.max-w-64 {
    max-width: 64px !important;
}

.min-w-66 {
    min-width: 66px !important;
}

.max-w-66 {
    max-width: 66px !important;
}

.min-w-68 {
    min-width: 68px !important;
}

.max-w-68 {
    max-width: 68px !important;
}

.min-w-70 {
    min-width: 70px !important;
}

.max-w-70 {
    max-width: 70px !important;
}

.min-w-72 {
    min-width: 72px !important;
}

.max-w-72 {
    max-width: 72px !important;
}

.min-w-74 {
    min-width: 74px !important;
}

.max-w-74 {
    max-width: 74px !important;
}

.min-w-76 {
    min-width: 76px !important;
}

.max-w-76 {
    max-width: 76px !important;
}

.min-w-78 {
    min-width: 78px !important;
}

.max-w-78 {
    max-width: 78px !important;
}

.min-w-80 {
    min-width: 80px !important;
}

.max-w-80 {
    max-width: 80px !important;
}

.min-w-82 {
    min-width: 82px !important;
}

.max-w-82 {
    max-width: 82px !important;
}

.min-w-84 {
    min-width: 84px !important;
}

.max-w-84 {
    max-width: 84px !important;
}

.min-w-86 {
    min-width: 86px !important;
}

.max-w-86 {
    max-width: 86px !important;
}

.min-w-88 {
    min-width: 88px !important;
}

.max-w-88 {
    max-width: 88px !important;
}

.min-w-90 {
    min-width: 90px !important;
}

.max-w-90 {
    max-width: 90px !important;
}

.min-w-92 {
    min-width: 92px !important;
}

.max-w-92 {
    max-width: 92px !important;
}

.min-w-94 {
    min-width: 94px !important;
}

.max-w-94 {
    max-width: 94px !important;
}

.min-w-96 {
    min-width: 96px !important;
}

.max-w-96 {
    max-width: 96px !important;
}

.min-w-98 {
    min-width: 98px !important;
}

.max-w-98 {
    max-width: 98px !important;
}

.min-w-100 {
    min-width: 100px !important;
}

.max-w-100 {
    max-width: 100px !important;
}

.min-w-102 {
    min-width: 102px !important;
}

.max-w-102 {
    max-width: 102px !important;
}

.min-w-104 {
    min-width: 104px !important;
}

.max-w-104 {
    max-width: 104px !important;
}

.min-w-106 {
    min-width: 106px !important;
}

.max-w-106 {
    max-width: 106px !important;
}

.min-w-108 {
    min-width: 108px !important;
}

.max-w-108 {
    max-width: 108px !important;
}

.min-w-110 {
    min-width: 110px !important;
}

.max-w-110 {
    max-width: 110px !important;
}

.min-w-112 {
    min-width: 112px !important;
}

.max-w-112 {
    max-width: 112px !important;
}

.min-w-114 {
    min-width: 114px !important;
}

.max-w-114 {
    max-width: 114px !important;
}

.min-w-116 {
    min-width: 116px !important;
}

.max-w-116 {
    max-width: 116px !important;
}

.min-w-118 {
    min-width: 118px !important;
}

.max-w-118 {
    max-width: 118px !important;
}

.min-w-120 {
    min-width: 120px !important;
}

.max-w-120 {
    max-width: 120px !important;
}

.min-w-122 {
    min-width: 122px !important;
}

.max-w-122 {
    max-width: 122px !important;
}

.min-w-124 {
    min-width: 124px !important;
}

.max-w-124 {
    max-width: 124px !important;
}

.min-w-126 {
    min-width: 126px !important;
}

.max-w-126 {
    max-width: 126px !important;
}

.min-w-128 {
    min-width: 128px !important;
}

.max-w-128 {
    max-width: 128px !important;
}

.min-w-130 {
    min-width: 130px !important;
}

.max-w-130 {
    max-width: 130px !important;
}

.min-w-132 {
    min-width: 132px !important;
}

.max-w-132 {
    max-width: 132px !important;
}

.min-w-134 {
    min-width: 134px !important;
}

.max-w-134 {
    max-width: 134px !important;
}

.min-w-136 {
    min-width: 136px !important;
}

.max-w-136 {
    max-width: 136px !important;
}

.min-w-138 {
    min-width: 138px !important;
}

.max-w-138 {
    max-width: 138px !important;
}

.min-w-140 {
    min-width: 140px !important;
}

.max-w-140 {
    max-width: 140px !important;
}

.min-w-142 {
    min-width: 142px !important;
}

.max-w-142 {
    max-width: 142px !important;
}

.min-w-144 {
    min-width: 144px !important;
}

.max-w-144 {
    max-width: 144px !important;
}

.min-w-146 {
    min-width: 146px !important;
}

.max-w-146 {
    max-width: 146px !important;
}

.min-w-148 {
    min-width: 148px !important;
}

.max-w-148 {
    max-width: 148px !important;
}

.min-w-150 {
    min-width: 150px !important;
}

.max-w-150 {
    max-width: 150px !important;
}

.min-w-152 {
    min-width: 152px !important;
}

.max-w-152 {
    max-width: 152px !important;
}

.min-w-154 {
    min-width: 154px !important;
}

.max-w-154 {
    max-width: 154px !important;
}

.min-w-156 {
    min-width: 156px !important;
}

.max-w-156 {
    max-width: 156px !important;
}

.min-w-158 {
    min-width: 158px !important;
}

.max-w-158 {
    max-width: 158px !important;
}

.min-w-160 {
    min-width: 160px !important;
}

.max-w-160 {
    max-width: 160px !important;
}

.min-w-162 {
    min-width: 162px !important;
}

.max-w-162 {
    max-width: 162px !important;
}

.min-w-164 {
    min-width: 164px !important;
}

.max-w-164 {
    max-width: 164px !important;
}

.min-w-166 {
    min-width: 166px !important;
}

.max-w-166 {
    max-width: 166px !important;
}

.min-w-168 {
    min-width: 168px !important;
}

.max-w-168 {
    max-width: 168px !important;
}

.min-w-170 {
    min-width: 170px !important;
}

.max-w-170 {
    max-width: 170px !important;
}

.min-w-172 {
    min-width: 172px !important;
}

.max-w-172 {
    max-width: 172px !important;
}

.min-w-174 {
    min-width: 174px !important;
}

.max-w-174 {
    max-width: 174px !important;
}

.min-w-176 {
    min-width: 176px !important;
}

.max-w-176 {
    max-width: 176px !important;
}

.min-w-178 {
    min-width: 178px !important;
}

.max-w-178 {
    max-width: 178px !important;
}

.min-w-180 {
    min-width: 180px !important;
}

.max-w-180 {
    max-width: 180px !important;
}

.min-w-182 {
    min-width: 182px !important;
}

.max-w-182 {
    max-width: 182px !important;
}

.min-w-184 {
    min-width: 184px !important;
}

.max-w-184 {
    max-width: 184px !important;
}

.min-w-186 {
    min-width: 186px !important;
}

.max-w-186 {
    max-width: 186px !important;
}

.min-w-188 {
    min-width: 188px !important;
}

.max-w-188 {
    max-width: 188px !important;
}

.min-w-190 {
    min-width: 190px !important;
}

.max-w-190 {
    max-width: 190px !important;
}

.min-w-192 {
    min-width: 192px !important;
}

.max-w-192 {
    max-width: 192px !important;
}

.min-w-194 {
    min-width: 194px !important;
}

.max-w-194 {
    max-width: 194px !important;
}

.min-w-196 {
    min-width: 196px !important;
}

.max-w-196 {
    max-width: 196px !important;
}

.min-w-198 {
    min-width: 198px !important;
}

.max-w-198 {
    max-width: 198px !important;
}

.min-w-200 {
    min-width: 200px !important;
}

.max-w-200 {
    max-width: 200px !important;
}

.min-w-202 {
    min-width: 202px !important;
}

.max-w-202 {
    max-width: 202px !important;
}

.min-w-204 {
    min-width: 204px !important;
}

.max-w-204 {
    max-width: 204px !important;
}

.min-w-206 {
    min-width: 206px !important;
}

.max-w-206 {
    max-width: 206px !important;
}

.min-w-208 {
    min-width: 208px !important;
}

.max-w-208 {
    max-width: 208px !important;
}

.min-w-210 {
    min-width: 210px !important;
}

.max-w-210 {
    max-width: 210px !important;
}

.min-w-212 {
    min-width: 212px !important;
}

.max-w-212 {
    max-width: 212px !important;
}

.min-w-214 {
    min-width: 214px !important;
}

.max-w-214 {
    max-width: 214px !important;
}

.min-w-216 {
    min-width: 216px !important;
}

.max-w-216 {
    max-width: 216px !important;
}

.min-w-218 {
    min-width: 218px !important;
}

.max-w-218 {
    max-width: 218px !important;
}

.min-w-220 {
    min-width: 220px !important;
}

.max-w-220 {
    max-width: 220px !important;
}

.min-w-222 {
    min-width: 222px !important;
}

.max-w-222 {
    max-width: 222px !important;
}

.min-w-224 {
    min-width: 224px !important;
}

.max-w-224 {
    max-width: 224px !important;
}

.min-w-226 {
    min-width: 226px !important;
}

.max-w-226 {
    max-width: 226px !important;
}

.min-w-228 {
    min-width: 228px !important;
}

.max-w-228 {
    max-width: 228px !important;
}

.min-w-230 {
    min-width: 230px !important;
}

.max-w-230 {
    max-width: 230px !important;
}

.min-w-232 {
    min-width: 232px !important;
}

.max-w-232 {
    max-width: 232px !important;
}

.min-w-234 {
    min-width: 234px !important;
}

.max-w-234 {
    max-width: 234px !important;
}

.min-w-236 {
    min-width: 236px !important;
}

.max-w-236 {
    max-width: 236px !important;
}

.min-w-238 {
    min-width: 238px !important;
}

.max-w-238 {
    max-width: 238px !important;
}

.min-w-240 {
    min-width: 240px !important;
}

.max-w-240 {
    max-width: 240px !important;
}

.min-w-242 {
    min-width: 242px !important;
}

.max-w-242 {
    max-width: 242px !important;
}

.min-w-244 {
    min-width: 244px !important;
}

.max-w-244 {
    max-width: 244px !important;
}

.min-w-246 {
    min-width: 246px !important;
}

.max-w-246 {
    max-width: 246px !important;
}

.min-w-248 {
    min-width: 248px !important;
}

.max-w-248 {
    max-width: 248px !important;
}

.min-w-250 {
    min-width: 250px !important;
}

.max-w-250 {
    max-width: 250px !important;
}

.min-w-252 {
    min-width: 252px !important;
}

.max-w-252 {
    max-width: 252px !important;
}

.min-w-254 {
    min-width: 254px !important;
}

.max-w-254 {
    max-width: 254px !important;
}

.min-w-256 {
    min-width: 256px !important;
}

.max-w-256 {
    max-width: 256px !important;
}

.min-w-258 {
    min-width: 258px !important;
}

.max-w-258 {
    max-width: 258px !important;
}

.min-w-260 {
    min-width: 260px !important;
}

.max-w-260 {
    max-width: 260px !important;
}

.min-w-262 {
    min-width: 262px !important;
}

.max-w-262 {
    max-width: 262px !important;
}

.min-w-264 {
    min-width: 264px !important;
}

.max-w-264 {
    max-width: 264px !important;
}

.min-w-266 {
    min-width: 266px !important;
}

.max-w-266 {
    max-width: 266px !important;
}

.min-w-268 {
    min-width: 268px !important;
}

.max-w-268 {
    max-width: 268px !important;
}

.min-w-270 {
    min-width: 270px !important;
}

.max-w-270 {
    max-width: 270px !important;
}

.min-w-272 {
    min-width: 272px !important;
}

.max-w-272 {
    max-width: 272px !important;
}

.min-w-274 {
    min-width: 274px !important;
}

.max-w-274 {
    max-width: 274px !important;
}

.min-w-276 {
    min-width: 276px !important;
}

.max-w-276 {
    max-width: 276px !important;
}

.min-w-278 {
    min-width: 278px !important;
}

.max-w-278 {
    max-width: 278px !important;
}

.min-w-280 {
    min-width: 280px !important;
}

.max-w-280 {
    max-width: 280px !important;
}

.min-w-282 {
    min-width: 282px !important;
}

.max-w-282 {
    max-width: 282px !important;
}

.min-w-284 {
    min-width: 284px !important;
}

.max-w-284 {
    max-width: 284px !important;
}

.min-w-286 {
    min-width: 286px !important;
}

.max-w-286 {
    max-width: 286px !important;
}

.min-w-288 {
    min-width: 288px !important;
}

.max-w-288 {
    max-width: 288px !important;
}

.min-w-290 {
    min-width: 290px !important;
}

.max-w-290 {
    max-width: 290px !important;
}

.min-w-292 {
    min-width: 292px !important;
}

.max-w-292 {
    max-width: 292px !important;
}

.min-w-294 {
    min-width: 294px !important;
}

.max-w-294 {
    max-width: 294px !important;
}

.min-w-296 {
    min-width: 296px !important;
}

.max-w-296 {
    max-width: 296px !important;
}

.min-w-298 {
    min-width: 298px !important;
}

.max-w-298 {
    max-width: 298px !important;
}

.min-w-300 {
    min-width: 300px !important;
}

.max-w-300 {
    max-width: 300px !important;
}

.min-w-302 {
    min-width: 302px !important;
}

.max-w-302 {
    max-width: 302px !important;
}

.min-w-304 {
    min-width: 304px !important;
}

.max-w-304 {
    max-width: 304px !important;
}

.min-w-306 {
    min-width: 306px !important;
}

.max-w-306 {
    max-width: 306px !important;
}

.min-w-308 {
    min-width: 308px !important;
}

.max-w-308 {
    max-width: 308px !important;
}

.min-w-310 {
    min-width: 310px !important;
}

.max-w-310 {
    max-width: 310px !important;
}

.min-w-312 {
    min-width: 312px !important;
}

.max-w-312 {
    max-width: 312px !important;
}

.min-w-314 {
    min-width: 314px !important;
}

.max-w-314 {
    max-width: 314px !important;
}

.min-w-316 {
    min-width: 316px !important;
}

.max-w-316 {
    max-width: 316px !important;
}

.min-w-318 {
    min-width: 318px !important;
}

.max-w-318 {
    max-width: 318px !important;
}

.min-w-320 {
    min-width: 320px !important;
}

.max-w-320 {
    max-width: 320px !important;
}

.min-w-322 {
    min-width: 322px !important;
}

.max-w-322 {
    max-width: 322px !important;
}

.min-w-324 {
    min-width: 324px !important;
}

.max-w-324 {
    max-width: 324px !important;
}

.min-w-326 {
    min-width: 326px !important;
}

.max-w-326 {
    max-width: 326px !important;
}

.min-w-328 {
    min-width: 328px !important;
}

.max-w-328 {
    max-width: 328px !important;
}

.min-w-330 {
    min-width: 330px !important;
}

.max-w-330 {
    max-width: 330px !important;
}

.min-w-332 {
    min-width: 332px !important;
}

.max-w-332 {
    max-width: 332px !important;
}

.min-w-334 {
    min-width: 334px !important;
}

.max-w-334 {
    max-width: 334px !important;
}

.min-w-336 {
    min-width: 336px !important;
}

.max-w-336 {
    max-width: 336px !important;
}

.min-w-338 {
    min-width: 338px !important;
}

.max-w-338 {
    max-width: 338px !important;
}

.min-w-340 {
    min-width: 340px !important;
}

.max-w-340 {
    max-width: 340px !important;
}

.min-w-342 {
    min-width: 342px !important;
}

.max-w-342 {
    max-width: 342px !important;
}

.min-w-344 {
    min-width: 344px !important;
}

.max-w-344 {
    max-width: 344px !important;
}

.min-w-346 {
    min-width: 346px !important;
}

.max-w-346 {
    max-width: 346px !important;
}

.min-w-348 {
    min-width: 348px !important;
}

.max-w-348 {
    max-width: 348px !important;
}

.min-w-350 {
    min-width: 350px !important;
}

.max-w-350 {
    max-width: 350px !important;
}

.min-w-352 {
    min-width: 352px !important;
}

.max-w-352 {
    max-width: 352px !important;
}

.min-w-354 {
    min-width: 354px !important;
}

.max-w-354 {
    max-width: 354px !important;
}

.min-w-356 {
    min-width: 356px !important;
}

.max-w-356 {
    max-width: 356px !important;
}

.min-w-358 {
    min-width: 358px !important;
}

.max-w-358 {
    max-width: 358px !important;
}

.min-w-360 {
    min-width: 360px !important;
}

.max-w-360 {
    max-width: 360px !important;
}

.min-w-362 {
    min-width: 362px !important;
}

.max-w-362 {
    max-width: 362px !important;
}

.min-w-364 {
    min-width: 364px !important;
}

.max-w-364 {
    max-width: 364px !important;
}

.min-w-366 {
    min-width: 366px !important;
}

.max-w-366 {
    max-width: 366px !important;
}

.min-w-368 {
    min-width: 368px !important;
}

.max-w-368 {
    max-width: 368px !important;
}

.min-w-370 {
    min-width: 370px !important;
}

.max-w-370 {
    max-width: 370px !important;
}

.min-w-372 {
    min-width: 372px !important;
}

.max-w-372 {
    max-width: 372px !important;
}

.min-w-374 {
    min-width: 374px !important;
}

.max-w-374 {
    max-width: 374px !important;
}

.min-w-376 {
    min-width: 376px !important;
}

.max-w-376 {
    max-width: 376px !important;
}

.min-w-378 {
    min-width: 378px !important;
}

.max-w-378 {
    max-width: 378px !important;
}

.min-w-380 {
    min-width: 380px !important;
}

.max-w-380 {
    max-width: 380px !important;
}

.min-w-382 {
    min-width: 382px !important;
}

.max-w-382 {
    max-width: 382px !important;
}

.min-w-384 {
    min-width: 384px !important;
}

.max-w-384 {
    max-width: 384px !important;
}

.min-w-386 {
    min-width: 386px !important;
}

.max-w-386 {
    max-width: 386px !important;
}

.min-w-388 {
    min-width: 388px !important;
}

.max-w-388 {
    max-width: 388px !important;
}

.min-w-390 {
    min-width: 390px !important;
}

.max-w-390 {
    max-width: 390px !important;
}

.min-w-392 {
    min-width: 392px !important;
}

.max-w-392 {
    max-width: 392px !important;
}

.min-w-394 {
    min-width: 394px !important;
}

.max-w-394 {
    max-width: 394px !important;
}

.min-w-396 {
    min-width: 396px !important;
}

.max-w-396 {
    max-width: 396px !important;
}

.min-w-398 {
    min-width: 398px !important;
}

.max-w-398 {
    max-width: 398px !important;
}

.min-w-400 {
    min-width: 400px !important;
}

.max-w-400 {
    max-width: 400px !important;
}

.min-w-402 {
    min-width: 402px !important;
}

.max-w-402 {
    max-width: 402px !important;
}

.min-w-404 {
    min-width: 404px !important;
}

.max-w-404 {
    max-width: 404px !important;
}

.min-w-406 {
    min-width: 406px !important;
}

.max-w-406 {
    max-width: 406px !important;
}

.min-w-408 {
    min-width: 408px !important;
}

.max-w-408 {
    max-width: 408px !important;
}

.min-w-410 {
    min-width: 410px !important;
}

.max-w-410 {
    max-width: 410px !important;
}

.min-w-412 {
    min-width: 412px !important;
}

.max-w-412 {
    max-width: 412px !important;
}

.min-w-414 {
    min-width: 414px !important;
}

.max-w-414 {
    max-width: 414px !important;
}

.min-w-416 {
    min-width: 416px !important;
}

.max-w-416 {
    max-width: 416px !important;
}

.min-w-418 {
    min-width: 418px !important;
}

.max-w-418 {
    max-width: 418px !important;
}

.min-w-420 {
    min-width: 420px !important;
}

.max-w-420 {
    max-width: 420px !important;
}

.min-w-422 {
    min-width: 422px !important;
}

.max-w-422 {
    max-width: 422px !important;
}

.min-w-424 {
    min-width: 424px !important;
}

.max-w-424 {
    max-width: 424px !important;
}

.min-w-426 {
    min-width: 426px !important;
}

.max-w-426 {
    max-width: 426px !important;
}

.min-w-428 {
    min-width: 428px !important;
}

.max-w-428 {
    max-width: 428px !important;
}

.min-w-430 {
    min-width: 430px !important;
}

.max-w-430 {
    max-width: 430px !important;
}

.min-w-432 {
    min-width: 432px !important;
}

.max-w-432 {
    max-width: 432px !important;
}

.min-w-434 {
    min-width: 434px !important;
}

.max-w-434 {
    max-width: 434px !important;
}

.min-w-436 {
    min-width: 436px !important;
}

.max-w-436 {
    max-width: 436px !important;
}

.min-w-438 {
    min-width: 438px !important;
}

.max-w-438 {
    max-width: 438px !important;
}

.min-w-440 {
    min-width: 440px !important;
}

.max-w-440 {
    max-width: 440px !important;
}

.min-w-442 {
    min-width: 442px !important;
}

.max-w-442 {
    max-width: 442px !important;
}

.min-w-444 {
    min-width: 444px !important;
}

.max-w-444 {
    max-width: 444px !important;
}

.min-w-446 {
    min-width: 446px !important;
}

.max-w-446 {
    max-width: 446px !important;
}

.min-w-448 {
    min-width: 448px !important;
}

.max-w-448 {
    max-width: 448px !important;
}

.min-w-450 {
    min-width: 450px !important;
}

.max-w-450 {
    max-width: 450px !important;
}

.min-w-452 {
    min-width: 452px !important;
}

.max-w-452 {
    max-width: 452px !important;
}

.min-w-454 {
    min-width: 454px !important;
}

.max-w-454 {
    max-width: 454px !important;
}

.min-w-456 {
    min-width: 456px !important;
}

.max-w-456 {
    max-width: 456px !important;
}

.min-w-458 {
    min-width: 458px !important;
}

.max-w-458 {
    max-width: 458px !important;
}

.min-w-460 {
    min-width: 460px !important;
}

.max-w-460 {
    max-width: 460px !important;
}

.min-w-462 {
    min-width: 462px !important;
}

.max-w-462 {
    max-width: 462px !important;
}

.min-w-464 {
    min-width: 464px !important;
}

.max-w-464 {
    max-width: 464px !important;
}

.min-w-466 {
    min-width: 466px !important;
}

.max-w-466 {
    max-width: 466px !important;
}

.min-w-468 {
    min-width: 468px !important;
}

.max-w-468 {
    max-width: 468px !important;
}

.min-w-470 {
    min-width: 470px !important;
}

.max-w-470 {
    max-width: 470px !important;
}

.min-w-472 {
    min-width: 472px !important;
}

.max-w-472 {
    max-width: 472px !important;
}

.min-w-474 {
    min-width: 474px !important;
}

.max-w-474 {
    max-width: 474px !important;
}

.min-w-476 {
    min-width: 476px !important;
}

.max-w-476 {
    max-width: 476px !important;
}

.min-w-478 {
    min-width: 478px !important;
}

.max-w-478 {
    max-width: 478px !important;
}

.min-w-480 {
    min-width: 480px !important;
}

.max-w-480 {
    max-width: 480px !important;
}

.min-w-482 {
    min-width: 482px !important;
}

.max-w-482 {
    max-width: 482px !important;
}

.min-w-484 {
    min-width: 484px !important;
}

.max-w-484 {
    max-width: 484px !important;
}

.min-w-486 {
    min-width: 486px !important;
}

.max-w-486 {
    max-width: 486px !important;
}

.min-w-488 {
    min-width: 488px !important;
}

.max-w-488 {
    max-width: 488px !important;
}

.min-w-490 {
    min-width: 490px !important;
}

.max-w-490 {
    max-width: 490px !important;
}

.min-w-492 {
    min-width: 492px !important;
}

.max-w-492 {
    max-width: 492px !important;
}

.min-w-494 {
    min-width: 494px !important;
}

.max-w-494 {
    max-width: 494px !important;
}

.min-w-496 {
    min-width: 496px !important;
}

.max-w-496 {
    max-width: 496px !important;
}

.min-w-498 {
    min-width: 498px !important;
}

.max-w-498 {
    max-width: 498px !important;
}

.min-w-500 {
    min-width: 500px !important;
}

.max-w-500 {
    max-width: 500px !important;
}

.min-w-502 {
    min-width: 502px !important;
}

.max-w-502 {
    max-width: 502px !important;
}

.min-w-504 {
    min-width: 504px !important;
}

.max-w-504 {
    max-width: 504px !important;
}

.min-w-506 {
    min-width: 506px !important;
}

.max-w-506 {
    max-width: 506px !important;
}

.min-w-508 {
    min-width: 508px !important;
}

.max-w-508 {
    max-width: 508px !important;
}

.min-w-510 {
    min-width: 510px !important;
}

.max-w-510 {
    max-width: 510px !important;
}

.min-w-512 {
    min-width: 512px !important;
}

.max-w-512 {
    max-width: 512px !important;
}

.min-w-514 {
    min-width: 514px !important;
}

.max-w-514 {
    max-width: 514px !important;
}

.min-w-516 {
    min-width: 516px !important;
}

.max-w-516 {
    max-width: 516px !important;
}

.min-w-518 {
    min-width: 518px !important;
}

.max-w-518 {
    max-width: 518px !important;
}

.min-w-520 {
    min-width: 520px !important;
}

.max-w-520 {
    max-width: 520px !important;
}

.min-w-522 {
    min-width: 522px !important;
}

.max-w-522 {
    max-width: 522px !important;
}

.min-w-524 {
    min-width: 524px !important;
}

.max-w-524 {
    max-width: 524px !important;
}

.min-w-526 {
    min-width: 526px !important;
}

.max-w-526 {
    max-width: 526px !important;
}

.min-w-528 {
    min-width: 528px !important;
}

.max-w-528 {
    max-width: 528px !important;
}

.min-w-530 {
    min-width: 530px !important;
}

.max-w-530 {
    max-width: 530px !important;
}

.min-w-532 {
    min-width: 532px !important;
}

.max-w-532 {
    max-width: 532px !important;
}

.min-w-534 {
    min-width: 534px !important;
}

.max-w-534 {
    max-width: 534px !important;
}

.min-w-536 {
    min-width: 536px !important;
}

.max-w-536 {
    max-width: 536px !important;
}

.min-w-538 {
    min-width: 538px !important;
}

.max-w-538 {
    max-width: 538px !important;
}

.min-w-540 {
    min-width: 540px !important;
}

.max-w-540 {
    max-width: 540px !important;
}

.min-w-542 {
    min-width: 542px !important;
}

.max-w-542 {
    max-width: 542px !important;
}

.min-w-544 {
    min-width: 544px !important;
}

.max-w-544 {
    max-width: 544px !important;
}

.min-w-546 {
    min-width: 546px !important;
}

.max-w-546 {
    max-width: 546px !important;
}

.min-w-548 {
    min-width: 548px !important;
}

.max-w-548 {
    max-width: 548px !important;
}

.min-w-550 {
    min-width: 550px !important;
}

.max-w-550 {
    max-width: 550px !important;
}

.min-w-552 {
    min-width: 552px !important;
}

.max-w-552 {
    max-width: 552px !important;
}

.min-w-554 {
    min-width: 554px !important;
}

.max-w-554 {
    max-width: 554px !important;
}

.min-w-556 {
    min-width: 556px !important;
}

.max-w-556 {
    max-width: 556px !important;
}

.min-w-558 {
    min-width: 558px !important;
}

.max-w-558 {
    max-width: 558px !important;
}

.min-w-560 {
    min-width: 560px !important;
}

.max-w-560 {
    max-width: 560px !important;
}

.min-w-562 {
    min-width: 562px !important;
}

.max-w-562 {
    max-width: 562px !important;
}

.min-w-564 {
    min-width: 564px !important;
}

.max-w-564 {
    max-width: 564px !important;
}

.min-w-566 {
    min-width: 566px !important;
}

.max-w-566 {
    max-width: 566px !important;
}

.min-w-568 {
    min-width: 568px !important;
}

.max-w-568 {
    max-width: 568px !important;
}

.min-w-570 {
    min-width: 570px !important;
}

.max-w-570 {
    max-width: 570px !important;
}

.min-w-572 {
    min-width: 572px !important;
}

.max-w-572 {
    max-width: 572px !important;
}

.min-w-574 {
    min-width: 574px !important;
}

.max-w-574 {
    max-width: 574px !important;
}

.min-w-576 {
    min-width: 576px !important;
}

.max-w-576 {
    max-width: 576px !important;
}

.min-w-578 {
    min-width: 578px !important;
}

.max-w-578 {
    max-width: 578px !important;
}

.min-w-580 {
    min-width: 580px !important;
}

.max-w-580 {
    max-width: 580px !important;
}

.min-w-582 {
    min-width: 582px !important;
}

.max-w-582 {
    max-width: 582px !important;
}

.min-w-584 {
    min-width: 584px !important;
}

.max-w-584 {
    max-width: 584px !important;
}

.min-w-586 {
    min-width: 586px !important;
}

.max-w-586 {
    max-width: 586px !important;
}

.min-w-588 {
    min-width: 588px !important;
}

.max-w-588 {
    max-width: 588px !important;
}

.min-w-590 {
    min-width: 590px !important;
}

.max-w-590 {
    max-width: 590px !important;
}

.min-w-592 {
    min-width: 592px !important;
}

.max-w-592 {
    max-width: 592px !important;
}

.min-w-594 {
    min-width: 594px !important;
}

.max-w-594 {
    max-width: 594px !important;
}

.min-w-596 {
    min-width: 596px !important;
}

.max-w-596 {
    max-width: 596px !important;
}

.min-w-598 {
    min-width: 598px !important;
}

.max-w-598 {
    max-width: 598px !important;
}

.min-w-600 {
    min-width: 600px !important;
}

.max-w-600 {
    max-width: 600px !important;
}

.min-w-602 {
    min-width: 602px !important;
}

.max-w-602 {
    max-width: 602px !important;
}

.min-w-604 {
    min-width: 604px !important;
}

.max-w-604 {
    max-width: 604px !important;
}

.min-w-606 {
    min-width: 606px !important;
}

.max-w-606 {
    max-width: 606px !important;
}

.min-w-608 {
    min-width: 608px !important;
}

.max-w-608 {
    max-width: 608px !important;
}

.min-w-610 {
    min-width: 610px !important;
}

.max-w-610 {
    max-width: 610px !important;
}

.min-w-612 {
    min-width: 612px !important;
}

.max-w-612 {
    max-width: 612px !important;
}

.min-w-614 {
    min-width: 614px !important;
}

.max-w-614 {
    max-width: 614px !important;
}

.min-w-616 {
    min-width: 616px !important;
}

.max-w-616 {
    max-width: 616px !important;
}

.min-w-618 {
    min-width: 618px !important;
}

.max-w-618 {
    max-width: 618px !important;
}

.min-w-620 {
    min-width: 620px !important;
}

.max-w-620 {
    max-width: 620px !important;
}

.min-w-622 {
    min-width: 622px !important;
}

.max-w-622 {
    max-width: 622px !important;
}

.min-w-624 {
    min-width: 624px !important;
}

.max-w-624 {
    max-width: 624px !important;
}

.min-w-626 {
    min-width: 626px !important;
}

.max-w-626 {
    max-width: 626px !important;
}

.min-w-628 {
    min-width: 628px !important;
}

.max-w-628 {
    max-width: 628px !important;
}

.min-w-630 {
    min-width: 630px !important;
}

.max-w-630 {
    max-width: 630px !important;
}

.min-w-632 {
    min-width: 632px !important;
}

.max-w-632 {
    max-width: 632px !important;
}

.min-w-634 {
    min-width: 634px !important;
}

.max-w-634 {
    max-width: 634px !important;
}

.min-w-636 {
    min-width: 636px !important;
}

.max-w-636 {
    max-width: 636px !important;
}

.min-w-638 {
    min-width: 638px !important;
}

.max-w-638 {
    max-width: 638px !important;
}

.min-w-640 {
    min-width: 640px !important;
}

.max-w-640 {
    max-width: 640px !important;
}

.min-w-642 {
    min-width: 642px !important;
}

.max-w-642 {
    max-width: 642px !important;
}

.min-w-644 {
    min-width: 644px !important;
}

.max-w-644 {
    max-width: 644px !important;
}

.min-w-646 {
    min-width: 646px !important;
}

.max-w-646 {
    max-width: 646px !important;
}

.min-w-648 {
    min-width: 648px !important;
}

.max-w-648 {
    max-width: 648px !important;
}

.min-w-650 {
    min-width: 650px !important;
}

.max-w-650 {
    max-width: 650px !important;
}

.min-w-652 {
    min-width: 652px !important;
}

.max-w-652 {
    max-width: 652px !important;
}

.min-w-654 {
    min-width: 654px !important;
}

.max-w-654 {
    max-width: 654px !important;
}

.min-w-656 {
    min-width: 656px !important;
}

.max-w-656 {
    max-width: 656px !important;
}

.min-w-658 {
    min-width: 658px !important;
}

.max-w-658 {
    max-width: 658px !important;
}

.min-w-660 {
    min-width: 660px !important;
}

.max-w-660 {
    max-width: 660px !important;
}

.min-w-662 {
    min-width: 662px !important;
}

.max-w-662 {
    max-width: 662px !important;
}

.min-w-664 {
    min-width: 664px !important;
}

.max-w-664 {
    max-width: 664px !important;
}

.min-w-666 {
    min-width: 666px !important;
}

.max-w-666 {
    max-width: 666px !important;
}

.min-w-668 {
    min-width: 668px !important;
}

.max-w-668 {
    max-width: 668px !important;
}

.min-w-670 {
    min-width: 670px !important;
}

.max-w-670 {
    max-width: 670px !important;
}

.min-w-672 {
    min-width: 672px !important;
}

.max-w-672 {
    max-width: 672px !important;
}

.min-w-674 {
    min-width: 674px !important;
}

.max-w-674 {
    max-width: 674px !important;
}

.min-w-676 {
    min-width: 676px !important;
}

.max-w-676 {
    max-width: 676px !important;
}

.min-w-678 {
    min-width: 678px !important;
}

.max-w-678 {
    max-width: 678px !important;
}

.min-w-680 {
    min-width: 680px !important;
}

.max-w-680 {
    max-width: 680px !important;
}

.min-w-682 {
    min-width: 682px !important;
}

.max-w-682 {
    max-width: 682px !important;
}

.min-w-684 {
    min-width: 684px !important;
}

.max-w-684 {
    max-width: 684px !important;
}

.min-w-686 {
    min-width: 686px !important;
}

.max-w-686 {
    max-width: 686px !important;
}

.min-w-688 {
    min-width: 688px !important;
}

.max-w-688 {
    max-width: 688px !important;
}

.min-w-690 {
    min-width: 690px !important;
}

.max-w-690 {
    max-width: 690px !important;
}

.min-w-692 {
    min-width: 692px !important;
}

.max-w-692 {
    max-width: 692px !important;
}

.min-w-694 {
    min-width: 694px !important;
}

.max-w-694 {
    max-width: 694px !important;
}

.min-w-696 {
    min-width: 696px !important;
}

.max-w-696 {
    max-width: 696px !important;
}

.min-w-698 {
    min-width: 698px !important;
}

.max-w-698 {
    max-width: 698px !important;
}

.min-w-700 {
    min-width: 700px !important;
}

.max-w-700 {
    max-width: 700px !important;
}

.min-w-702 {
    min-width: 702px !important;
}

.max-w-702 {
    max-width: 702px !important;
}

.min-w-704 {
    min-width: 704px !important;
}

.max-w-704 {
    max-width: 704px !important;
}

.min-w-706 {
    min-width: 706px !important;
}

.max-w-706 {
    max-width: 706px !important;
}

.min-w-708 {
    min-width: 708px !important;
}

.max-w-708 {
    max-width: 708px !important;
}

.min-w-710 {
    min-width: 710px !important;
}

.max-w-710 {
    max-width: 710px !important;
}

.min-w-712 {
    min-width: 712px !important;
}

.max-w-712 {
    max-width: 712px !important;
}

.min-w-714 {
    min-width: 714px !important;
}

.max-w-714 {
    max-width: 714px !important;
}

.min-w-716 {
    min-width: 716px !important;
}

.max-w-716 {
    max-width: 716px !important;
}

.min-w-718 {
    min-width: 718px !important;
}

.max-w-718 {
    max-width: 718px !important;
}

.min-w-720 {
    min-width: 720px !important;
}

.max-w-720 {
    max-width: 720px !important;
}

.min-w-722 {
    min-width: 722px !important;
}

.max-w-722 {
    max-width: 722px !important;
}

.min-w-724 {
    min-width: 724px !important;
}

.max-w-724 {
    max-width: 724px !important;
}

.min-w-726 {
    min-width: 726px !important;
}

.max-w-726 {
    max-width: 726px !important;
}

.min-w-728 {
    min-width: 728px !important;
}

.max-w-728 {
    max-width: 728px !important;
}

.min-w-730 {
    min-width: 730px !important;
}

.max-w-730 {
    max-width: 730px !important;
}

.min-w-732 {
    min-width: 732px !important;
}

.max-w-732 {
    max-width: 732px !important;
}

.min-w-734 {
    min-width: 734px !important;
}

.max-w-734 {
    max-width: 734px !important;
}

.min-w-736 {
    min-width: 736px !important;
}

.max-w-736 {
    max-width: 736px !important;
}

.min-w-738 {
    min-width: 738px !important;
}

.max-w-738 {
    max-width: 738px !important;
}

.min-w-740 {
    min-width: 740px !important;
}

.max-w-740 {
    max-width: 740px !important;
}

.min-w-742 {
    min-width: 742px !important;
}

.max-w-742 {
    max-width: 742px !important;
}

.min-w-744 {
    min-width: 744px !important;
}

.max-w-744 {
    max-width: 744px !important;
}

.min-w-746 {
    min-width: 746px !important;
}

.max-w-746 {
    max-width: 746px !important;
}

.min-w-748 {
    min-width: 748px !important;
}

.max-w-748 {
    max-width: 748px !important;
}

.min-w-750 {
    min-width: 750px !important;
}

.max-w-750 {
    max-width: 750px !important;
}

.min-w-752 {
    min-width: 752px !important;
}

.max-w-752 {
    max-width: 752px !important;
}

.min-w-754 {
    min-width: 754px !important;
}

.max-w-754 {
    max-width: 754px !important;
}

.min-w-756 {
    min-width: 756px !important;
}

.max-w-756 {
    max-width: 756px !important;
}

.min-w-758 {
    min-width: 758px !important;
}

.max-w-758 {
    max-width: 758px !important;
}

.min-w-760 {
    min-width: 760px !important;
}

.max-w-760 {
    max-width: 760px !important;
}

.min-w-762 {
    min-width: 762px !important;
}

.max-w-762 {
    max-width: 762px !important;
}

.min-w-764 {
    min-width: 764px !important;
}

.max-w-764 {
    max-width: 764px !important;
}

.min-w-766 {
    min-width: 766px !important;
}

.max-w-766 {
    max-width: 766px !important;
}

.min-w-768 {
    min-width: 768px !important;
}

.max-w-768 {
    max-width: 768px !important;
}

.min-w-770 {
    min-width: 770px !important;
}

.max-w-770 {
    max-width: 770px !important;
}

.min-w-772 {
    min-width: 772px !important;
}

.max-w-772 {
    max-width: 772px !important;
}

.min-w-774 {
    min-width: 774px !important;
}

.max-w-774 {
    max-width: 774px !important;
}

.min-w-776 {
    min-width: 776px !important;
}

.max-w-776 {
    max-width: 776px !important;
}

.min-w-778 {
    min-width: 778px !important;
}

.max-w-778 {
    max-width: 778px !important;
}

.min-w-780 {
    min-width: 780px !important;
}

.max-w-780 {
    max-width: 780px !important;
}

.min-w-782 {
    min-width: 782px !important;
}

.max-w-782 {
    max-width: 782px !important;
}

.min-w-784 {
    min-width: 784px !important;
}

.max-w-784 {
    max-width: 784px !important;
}

.min-w-786 {
    min-width: 786px !important;
}

.max-w-786 {
    max-width: 786px !important;
}

.min-w-788 {
    min-width: 788px !important;
}

.max-w-788 {
    max-width: 788px !important;
}

.min-w-790 {
    min-width: 790px !important;
}

.max-w-790 {
    max-width: 790px !important;
}

.min-w-792 {
    min-width: 792px !important;
}

.max-w-792 {
    max-width: 792px !important;
}

.min-w-794 {
    min-width: 794px !important;
}

.max-w-794 {
    max-width: 794px !important;
}

.min-w-796 {
    min-width: 796px !important;
}

.max-w-796 {
    max-width: 796px !important;
}

.min-w-798 {
    min-width: 798px !important;
}

.max-w-798 {
    max-width: 798px !important;
}

.min-w-800 {
    min-width: 800px !important;
}

.max-w-800 {
    max-width: 800px !important;
}

.min-w-802 {
    min-width: 802px !important;
}

.max-w-802 {
    max-width: 802px !important;
}

.min-w-804 {
    min-width: 804px !important;
}

.max-w-804 {
    max-width: 804px !important;
}

.min-w-806 {
    min-width: 806px !important;
}

.max-w-806 {
    max-width: 806px !important;
}

.min-w-808 {
    min-width: 808px !important;
}

.max-w-808 {
    max-width: 808px !important;
}

.min-w-810 {
    min-width: 810px !important;
}

.max-w-810 {
    max-width: 810px !important;
}

.min-w-812 {
    min-width: 812px !important;
}

.max-w-812 {
    max-width: 812px !important;
}

.min-w-814 {
    min-width: 814px !important;
}

.max-w-814 {
    max-width: 814px !important;
}

.min-w-816 {
    min-width: 816px !important;
}

.max-w-816 {
    max-width: 816px !important;
}

.min-w-818 {
    min-width: 818px !important;
}

.max-w-818 {
    max-width: 818px !important;
}

.min-w-820 {
    min-width: 820px !important;
}

.max-w-820 {
    max-width: 820px !important;
}

.min-w-822 {
    min-width: 822px !important;
}

.max-w-822 {
    max-width: 822px !important;
}

.min-w-824 {
    min-width: 824px !important;
}

.max-w-824 {
    max-width: 824px !important;
}

.min-w-826 {
    min-width: 826px !important;
}

.max-w-826 {
    max-width: 826px !important;
}

.min-w-828 {
    min-width: 828px !important;
}

.max-w-828 {
    max-width: 828px !important;
}

.min-w-830 {
    min-width: 830px !important;
}

.max-w-830 {
    max-width: 830px !important;
}

.min-w-832 {
    min-width: 832px !important;
}

.max-w-832 {
    max-width: 832px !important;
}

.min-w-834 {
    min-width: 834px !important;
}

.max-w-834 {
    max-width: 834px !important;
}

.min-w-836 {
    min-width: 836px !important;
}

.max-w-836 {
    max-width: 836px !important;
}

.min-w-838 {
    min-width: 838px !important;
}

.max-w-838 {
    max-width: 838px !important;
}

.min-w-840 {
    min-width: 840px !important;
}

.max-w-840 {
    max-width: 840px !important;
}

.min-w-842 {
    min-width: 842px !important;
}

.max-w-842 {
    max-width: 842px !important;
}

.min-w-844 {
    min-width: 844px !important;
}

.max-w-844 {
    max-width: 844px !important;
}

.min-w-846 {
    min-width: 846px !important;
}

.max-w-846 {
    max-width: 846px !important;
}

.min-w-848 {
    min-width: 848px !important;
}

.max-w-848 {
    max-width: 848px !important;
}

.min-w-850 {
    min-width: 850px !important;
}

.max-w-850 {
    max-width: 850px !important;
}

.min-w-852 {
    min-width: 852px !important;
}

.max-w-852 {
    max-width: 852px !important;
}

.min-w-854 {
    min-width: 854px !important;
}

.max-w-854 {
    max-width: 854px !important;
}

.min-w-856 {
    min-width: 856px !important;
}

.max-w-856 {
    max-width: 856px !important;
}

.min-w-858 {
    min-width: 858px !important;
}

.max-w-858 {
    max-width: 858px !important;
}

.min-w-860 {
    min-width: 860px !important;
}

.max-w-860 {
    max-width: 860px !important;
}

.min-w-862 {
    min-width: 862px !important;
}

.max-w-862 {
    max-width: 862px !important;
}

.min-w-864 {
    min-width: 864px !important;
}

.max-w-864 {
    max-width: 864px !important;
}

.min-w-866 {
    min-width: 866px !important;
}

.max-w-866 {
    max-width: 866px !important;
}

.min-w-868 {
    min-width: 868px !important;
}

.max-w-868 {
    max-width: 868px !important;
}

.min-w-870 {
    min-width: 870px !important;
}

.max-w-870 {
    max-width: 870px !important;
}

.min-w-872 {
    min-width: 872px !important;
}

.max-w-872 {
    max-width: 872px !important;
}

.min-w-874 {
    min-width: 874px !important;
}

.max-w-874 {
    max-width: 874px !important;
}

.min-w-876 {
    min-width: 876px !important;
}

.max-w-876 {
    max-width: 876px !important;
}

.min-w-878 {
    min-width: 878px !important;
}

.max-w-878 {
    max-width: 878px !important;
}

.min-w-880 {
    min-width: 880px !important;
}

.max-w-880 {
    max-width: 880px !important;
}

.min-w-882 {
    min-width: 882px !important;
}

.max-w-882 {
    max-width: 882px !important;
}

.min-w-884 {
    min-width: 884px !important;
}

.max-w-884 {
    max-width: 884px !important;
}

.min-w-886 {
    min-width: 886px !important;
}

.max-w-886 {
    max-width: 886px !important;
}

.min-w-888 {
    min-width: 888px !important;
}

.max-w-888 {
    max-width: 888px !important;
}

.min-w-890 {
    min-width: 890px !important;
}

.max-w-890 {
    max-width: 890px !important;
}

.min-w-892 {
    min-width: 892px !important;
}

.max-w-892 {
    max-width: 892px !important;
}

.min-w-894 {
    min-width: 894px !important;
}

.max-w-894 {
    max-width: 894px !important;
}

.min-w-896 {
    min-width: 896px !important;
}

.max-w-896 {
    max-width: 896px !important;
}

.min-w-898 {
    min-width: 898px !important;
}

.max-w-898 {
    max-width: 898px !important;
}

.min-w-900 {
    min-width: 900px !important;
}

.max-w-900 {
    max-width: 900px !important;
}

.h-1vh {
    height: 10vh !important;
}

.h-2vh {
    height: 20vh !important;
}

.h-3vh {
    height: 30vh !important;
}

.h-4vh {
    height: 40vh !important;
}

.h-5vh {
    height: 50vh !important;
}

.h-6vh {
    height: 60vh !important;
}

.h-7vh {
    height: 70vh !important;
}

.min-h-4vh {
    min-height: 40vh !important;
}

.min-h-7vh {
    min-height: 70vh !important;
}

.h-8vh {
    height: 80vh !important;
}

.h-9vh {
    height: 90vh !important;
}

.h-10vh {
    height: 100vh !important;
}

.h-2 {
    height: 2px !important;
}

.h-4 {
    height: 4px !important;
}

.h-6 {
    height: 6px !important;
}

.h-8 {
    height: 8px !important;
}

.h-10 {
    height: 10px !important;
}

.h-12 {
    height: 12px !important;
}

.h-14 {
    height: 14px !important;
}

.h-16 {
    height: 16px !important;
}

.h-18 {
    height: 18px !important;
}

.h-20 {
    height: 20px !important;
}

.h-22 {
    height: 22px !important;
}

.h-24 {
    height: 24px !important;
}

.h-26 {
    height: 26px !important;
}

.h-28 {
    height: 28px !important;
}

.h-30 {
    height: 30px !important;
}

.h-32 {
    height: 32px !important;
}

.h-34 {
    height: 34px !important;
}

.h-36 {
    height: 36px !important;
}

.h-38 {
    height: 38px !important;
}

.h-40 {
    height: 40px !important;
}

.h-42 {
    height: 42px !important;
}

.h-44 {
    height: 44px !important;
}

.h-46 {
    height: 46px !important;
}

.h-48 {
    height: 48px !important;
}

.h-50 {
    height: 50px !important;
}

.h-52 {
    height: 52px !important;
}

.h-54 {
    height: 54px !important;
}

.h-56 {
    height: 56px !important;
}

.h-58 {
    height: 58px !important;
}

.h-60 {
    height: 60px !important;
}

.h-62 {
    height: 62px !important;
}

.h-64 {
    height: 64px !important;
}

.h-66 {
    height: 66px !important;
}

.h-68 {
    height: 68px !important;
}

.h-70 {
    height: 70px !important;
}

.h-80 {
    height: 80px !important;
}

.h-90 {
    height: 90px !important;
}

.h-100 {
    height: 100px !important;
}

.h-110 {
    height: 110px !important;
}

.h-120 {
    height: 120px !important;
}

.h-130 {
    height: 130px !important;
}

.h-140 {
    height: 140px !important;
}

.h-150 {
    height: 150px !important;
}

.h-160 {
    height: 160px !important;
}

.h-170 {
    height: 170px !important;
}

.h-180 {
    height: 180px !important;
}

.h-190 {
    height: 190px !important;
}

.h-200 {
    height: 200px !important;
}

.h-210 {
    height: 210px !important;
}

.h-220 {
    height: 220px !important;
}

.h-230 {
    height: 230px !important;
}

.h-240 {
    height: 240px !important;
}

.h-250 {
    height: 250px !important;
}

.h-260 {
    height: 260px !important;
}

.h-270 {
    height: 270px !important;
}

.h-280 {
    height: 280px !important;
}

.h-290 {
    height: 290px !important;
}

.h-300 {
    height: 300px !important;
}

.h-310 {
    height: 310px !important;
}

.h-320 {
    height: 320px !important;
}

.h-330 {
    height: 330px !important;
}

.h-340 {
    height: 340px !important;
}

.h-350 {
    height: 350px !important;
}

.h-360 {
    height: 360px !important;
}

.h-370 {
    height: 370px !important;
}

.h-380 {
    height: 380px !important;
}

.h-390 {
    height: 390px !important;
}

.h-400 {
    height: 400px !important;
}

.h-410 {
    height: 410px !important;
}

.min-h-200 {
    min-height: 200px !important;
}

.min-h-360 {
    min-height: 360px !important;
}


.h-2 {
    height: 2px !important;
}

.h-4 {
    height: 4px !important;
}

.h-6 {
    height: 6px !important;
}

.h-8 {
    height: 8px !important;
}

.h-10 {
    height: 10px !important;
}

.h-12 {
    height: 12px !important;
}

.h-14 {
    height: 14px !important;
}

.h-16 {
    height: 16px !important;
}

.h-18 {
    height: 18px !important;
}

.h-20 {
    height: 20px !important;
}

.h-22 {
    height: 22px !important;
}

.h-24 {
    height: 24px !important;
}

.h-26 {
    height: 26px !important;
}

.h-28 {
    height: 28px !important;
}

.h-30 {
    height: 30px !important;
}

.h-32 {
    height: 32px !important;
}

.h-34 {
    height: 34px !important;
}

.h-36 {
    height: 36px !important;
}

.h-38 {
    height: 38px !important;
}

.h-40 {
    height: 40px !important;
}

.h-42 {
    height: 42px !important;
}

.h-44 {
    height: 44px !important;
}

.h-46 {
    height: 46px !important;
}

.h-48 {
    height: 48px !important;
}

.h-50 {
    height: 50px !important;
}

.h-52 {
    height: 52px !important;
}

.h-54 {
    height: 54px !important;
}

.h-56 {
    height: 56px !important;
}

.h-58 {
    height: 58px !important;
}

.h-60 {
    height: 60px !important;
}

.h-62 {
    height: 62px !important;
}

.h-64 {
    height: 64px !important;
}

.h-66 {
    height: 66px !important;
}

.h-68 {
    height: 68px !important;
}

.h-70 {
    height: 70px !important;
}

.h-80 {
    height: 80px !important;
}

.h-90 {
    height: 90px !important;
}

.h-100 {
    height: 100px !important;
}

.h-110 {
    height: 110px !important;
}

.h-120 {
    height: 120px !important;
}

.h-130 {
    height: 130px !important;
}

.h-140 {
    height: 140px !important;
}

.h-150 {
    height: 150px !important;
}

.h-160 {
    height: 160px !important;
}

.h-170 {
    height: 170px !important;
}

.h-180 {
    height: 180px !important;
}

.h-190 {
    height: 190px !important;
}

.h-200 {
    height: 200px !important;
}

.h-210 {
    height: 210px !important;
}

.h-220 {
    height: 220px !important;
}

.h-230 {
    height: 230px !important;
}

.h-240 {
    height: 240px !important;
}

.h-250 {
    height: 250px !important;
}

.h-260 {
    height: 260px !important;
}

.h-270 {
    height: 270px !important;
}

.h-280 {
    height: 280px !important;
}

.h-290 {
    height: 290px !important;
}

.h-300 {
    height: 300px !important;
}

.h-310 {
    height: 310px !important;
}

.h-320 {
    height: 320px !important;
}

.h-330 {
    height: 330px !important;
}

.h-340 {
    height: 340px !important;
}

.h-350 {
    height: 350px !important;
}

.h-360 {
    height: 360px !important;
}

.h-370 {
    height: 370px !important;
}

.h-380 {
    height: 380px !important;
}

.h-390 {
    height: 390px !important;
}

.h-400 {
    height: 400px !important;
}

.h-410 {
    height: 410px !important;
}

.cursor-p {
    cursor: pointer !important;
}

.transition-03 {
    transition: .3s !important;
}

.hover-translateX:hover {
    transform: translateY(-20px) !important;
}

.btn {
    border-radius: 50px !important;
    padding: 12px 32px !important;
}

.full-radi {
    border-radius: 50% !important;
}

.radi-2 {
    border-radius: 2px !important;
}

.radi-4 {
    border-radius: 4px !important;
}

.radi-6 {
    border-radius: 6px !important;
}

.radi-8 {
    border-radius: 8px !important;
}

.radi-10 {
    border-radius: 10px !important;
}

.radi-12 {
    border-radius: 12px !important;
}

.radi-14 {
    border-radius: 14px !important;
}

.radi-16 {
    border-radius: 16px !important;
}

.radi-18 {
    border-radius: 18px !important;
}

.radi-20 {
    border-radius: 20px !important;
}

.radi-22 {
    border-radius: 22px !important;
}

.radi-24 {
    border-radius: 24px !important;
}

.radi-26 {
    border-radius: 26px !important;
}

.radi-28 {
    border-radius: 28px !important;
}

.radi-30 {
    border-radius: 30px !important;
}

.radi-32 {
    border-radius: 32px !important;
}

.radi-34 {
    border-radius: 34px !important;
}

.radi-36 {
    border-radius: 36px !important;
}

.radi-38 {
    border-radius: 38px !important;
}

.radi-40 {
    border-radius: 40px !important;
}

.hover-backGreen:hover {
    background-color: var(--green-color) !important;
}

.position-rel {
    position: relative !important;
}

.position-abs {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.d-grid {
    display: grid !important;
}

.grid-colt-1 {
    grid-template-columns: repeat(1, 1fr) !important;
}

.grid-colt-2 {
    grid-template-columns: repeat(2, 1fr) !important;
}

.grid-colt-3 {
    grid-template-columns: repeat(3, 1fr) !important;
}

.grid-colt-4 {
    grid-template-columns: repeat(4, 1fr) !important;
}

.grid-colt-5 {
    grid-template-columns: repeat(5, 1fr) !important;
}

.grid-colt-6 {
    grid-template-columns: repeat(6, 1fr) !important;
}

.grid-colt-7 {
    grid-template-columns: repeat(7, 1fr) !important;
}

.grid-colt-8 {
    grid-template-columns: repeat(8, 1fr) !important;
}

.grid-colt-9 {
    grid-template-columns: repeat(9, 1fr) !important;
}

.grid-colt-10 {
    grid-template-columns: repeat(10, 1fr) !important;
}

.grid-col-1-3 {
    grid-column: 1/3 !important;
}

.grid-col-1-4 {
    grid-column: 1/4 !important;
}

.grid-col-1-5 {
    grid-column: 1/5 !important;
}

.grid-col-2-4 {
    grid-column: 2/4 !important;
}

.grid-col-5-6 {
    grid-column: 5/6 !important;
}

.grid-col-7-8 {
    grid-column: 7/8 !important;
}

.grid-col-9-10 {
    grid-column: 9/10 !important;
}

.grid-row-1-3 {
    grid-row: 1/3 !important;
}

.grid-row-1-4 {
    grid-row: 1/4 !important;
}

.grid-row-1-5 {
    grid-row: 1/5 !important;
}

.grid-row-2-3 {
    grid-row: 2/3 !important;
}

.grid-colt-auto-336 {
    grid-template-columns: repeat(auto-fill, minmax(336px, 1fr)) !important;
}

.grid-colt-auto-260 {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)) !important;
}

.grid-colt-auto-226 {
    grid-template-columns: repeat(auto-fill, minmax(226px, 1fr)) !important;
}

.grid-colt-auto-186 {
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr)) !important;
}

.shadow-4-10-grey {
    box-shadow: 0 4px 10px rgba(0, 0, 0, .1) !important;
}

.shadow-4-0-2000-grey {
    box-shadow: 0 4px 0px 2000px rgba(0, 0, 0, .8) !important;
}

.t-0 {
    top: 0 !important;
}

.b-0 {
    bottom: 0 !important;
}

.l-0 {
    left: 0 !important;
}

.r-0 {
    right: 0 !important;
}

.t-8 {
    top: 8px !important;
}

.t-10 {
    top: 10px !important;
}

.b-10 {
    bottom: 10px !important;
}

.l-8 {
    left: 8px !important;
}

.l-10 {
    left: 10px !important;
}

.r-10 {
    right: 10px !important;
}

.t-20 {
    top: 20px !important;
}

.b-20 {
    bottom: 20px !important;
}

.l-20 {
    left: 20px !important;
}

.r-20 {
    right: 20px !important;
}

.t-30 {
    top: 30px !important;
}

.b-30 {
    bottom: 30px !important;
}

.l-30 {
    left: 30px !important;
}

.select-none {
    user-select: none !important;
}

.r-30 {
    right: 30px !important;
}

.t-40 {
    top: 40px !important;
}

.b-40 {
    bottom: 40px !important;
}

.l-40 {
    left: 40px !important;
}

.r-40 {
    right: 40px !important;
}

.t-50 {
    top: 50px !important;
}

.b-50 {
    bottom: 50px !important;
}

.l-50 {
    left: 50px !important;
}

.r-50 {
    right: 50px !important;
}

.t-60 {
    top: 60px !important;
}

.b-60 {
    bottom: 60px !important;
}

.l-60 {
    left: 60px !important;
}

.r-60 {
    right: 60px !important;
}

.t-70 {
    top: 70px !important;
}

.b-70 {
    bottom: 70px !important;
}

.l-70 {
    left: 70px !important;
}

.r-70 {
    right: 70px !important;
}

.t-80 {
    top: 80px !important;
}

.b-80 {
    bottom: 80px !important;
}

.l-80 {
    left: 80px !important;
}

.r-80 {
    right: 80px !important;
}

.t-90 {
    top: 90px !important;
}

.b-90 {
    bottom: 90px !important;
}

.l-90 {
    left: 90px !important;
}

.r-90 {
    right: 90px !important;
}

.t-100 {
    top: 100px !important;
}

.b-100 {
    bottom: 100px !important;
}

.l-100 {
    left: 100px !important;
}

.r-100 {
    right: 100px !important;
}

.t-110 {
    top: 110px !important;
}

.inset-0 {
    inset: 0 !important;
}

.t-50p {
    top: 50% !important;
}

.l-50p {
    left: 50% !important;
}

.r-50p {
    right: 50% !important;
}

.b-50p {
    bottom: 50% !important;
}

.transform-50p {
    transform: translate(-50%, -50%) !important;
}

.z-1 {
    z-index: 1 !important;
}

.z-2 {
    z-index: 2 !important;
}

.z-3 {
    z-index: 3 !important;
}

.z-4 {
    z-index: 4 !important;
}

.z-5 {
    z-index: 5 !important;
}

.z-6 {
    z-index: 6 !important;
}

.z-7 {
    z-index: 7 !important;
}

.z-8 {
    z-index: 8 !important;
}

.z-9 {
    z-index: 9 !important;
}

.z-10 {
    z-index: 10 !important;
}

.z-11 {
    z-index: 11 !important;
}

.z-12 {
    z-index: 12 !important;
}

.z-13 {
    z-index: 13 !important;
}

.z-14 {
    z-index: 14 !important;
}

.z-15 {
    z-index: 15 !important;
}

.popup {
    position: absolute !important;
    z-index: 2 !important;
    inset: 0 !important;
}

opacity-1 {
    opacity: 1 !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-01 {
    opacity: .1 !important;
}

.opacity-02 {
    opacity: .2 !important;
}

.opacity-03 {
    opacity: .3 !important;
}

.opacity-04 {
    opacity: .4 !important;
}

.opacity-05 {
    opacity: .5 !important;
}

.opacity-06 {
    opacity: .6 !important;
}

.opacity-07 {
    opacity: .7 !important;
}

.opacity-08 {
    opacity: .8 !important;
}

.opacity-09 {
    opacity: .9 !important;
}

.hover-op01:hover {
    opacity: .1 !important;
}

.hover-op02:hover {
    opacity: .2 !important;
}

.hover-op03:hover {
    opacity: .3 !important;
}

.hover-op04:hover {
    opacity: .4 !important;
}

.hover-op05:hover {
    opacity: .5 !important;
}

.hover-op06:hover {
    opacity: .6 !important;
}

.hover-op07:hover {
    opacity: .7 !important;
}

.hover-op08:hover {
    opacity: .8 !important;
}

.hover-op09:hover {
    opacity: .9 !important;
}

.pointer-none {
    pointer-events: none !important;
}

.outline-none {
    outline: none !important;
}

.background-trans {
    background-color: transparent !important;
}

.border-none {
    border: none !important;
}

.text-cap {
    text-transform: capitalize !important;
}

.text-upper {
    text-transform: uppercase !important;
}

.shadow-sm {
    box-shadow: 0 4px 10px rgba(0, 0, 0, .08) !important;
}

.shadow-md {
    box-shadow: 0 4px 18px rgba(0, 0, 0, .12) !important;
}

.shadow-lg {
    box-shadow: 0 4px 30px rgba(0, 0, 0, .16) !important;
}

.overflow-h {
    overflow: hidden !important;
}

.overflow-v {
    overflow: visible !important;
}

.resize-v {
    resize: vertical !important;
}

.resize-h {
    resize: horizontal !important;
}

.resize-none {
    resize: none !important;
}

.obj-fit {
    object-fit: cover !important;
}

@media (max-width:1440px) {

    .max-inner {
        max-width: 1000px !important;
    }

    .md-d-none {
        display: none !important;
    }

    .md-flex-col {
        flex-direction: column !important;
    }

    .md-max-w-360 {
        max-width: 360px !important;
    }

    .md-max-w-400 {
        max-width: 400px !important;
    }

    .md-max-w-440 {
        max-width: 440px !important;
    }

    .md-fs-48 {
        font-size: 48px !important;
    }

    .md-fs-36 {
        font-size: 36px !important;
    }
}

@media (min-width:1440px) {
    .d-none-md {
        display: none !important;
    }

    .d-none-md {
        display: none !important;
    }

    .flex-col-md {
        flex-direction: column !important;
    }

}

@media (max-width:1044px) {

    .sm-p-t-0 {
        padding-top: 0 !important;
    }

    .sm-p-t-10 {
        padding-top: 10px !important;
    }

    .sm-p-t-20 {
        padding-top: 20px !important;
    }

    .sm-p-l-0 {
        padding-left: 0 !important;
    }

    .sm-p-l-10 {
        padding-left: 10px !important;
    }

    .sm-p-l-20 {
        padding-left: 20px !important;
    }

    .sm-p-r-0 {
        padding-right: 0 !important;
    }

    .sm-p-r-10 {
        padding-right: 10px !important;
    }

    .sm-p-b-0 {
        padding-bottom: 0 !important;
    }

    .sm-p-b-10 {
        padding-bottom: 10px !important;
    }

    .sm-p-b-20 {
        padding-bottom: 20px !important;
    }

    .sm-border-0 {
        border: 0 !important;
    }

    .sm-fs-18 {
        font-size: 18px !important;
    }

    .sm-fs-14 {
        font-size: 14px !important;
    }

    .sm-fs-24 {
        font-size: 24px !important;
    }

    .sm-fs-26 {
        font-size: 26px !important;
    }

    .sm-fs-28 {
        font-size: 28px !important;
    }

    .sm-fs-30 {
        font-size: 30px !important;
    }

    .sm-fs-32 {
        font-size: 32px !important;
    }

    .sm-fs-34 {
        font-size: 34px !important;
    }

    .sm-fs-36 {
        font-size: 36px !important;
    }

    .sm-fs-38 {
        font-size: 38px !important;
    }

    .sm-fs-40 {
        font-size: 40px !important;
    }

    .sm-fs-42 {
        font-size: 42px !important;
    }

    .sm-flex-col {
        flex-direction: column !important;
    }

    .sm-flex-row {
        flex-direction: row !important;
    }

    .sm-text-center {
        text-align: center !important;
    }

    .max-inner {
        padding-left: 20px !important;
        padding-right: 20px !important;
        max-width: 774px !important;
    }

    .max-outer {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .sm-d-none {
        display: none !important;
    }

    .sm-d-none {
        display: none !important;
    }

    .sm-flex-wrap {
        flex-wrap: wrap !important;
    }

    .sm-gap-2 {
        gap: 2px !important;
    }

    .sm-gap-4 {
        gap: 4px !important;
    }

    .sm-gap-6 {
        gap: 6px !important;
    }

    .sm-gap-8 {
        gap: 8px !important;
    }

    .sm-gap-10 {
        gap: 10px !important;
    }

    .sm-gap-12 {
        gap: 12px !important;
    }

    .sm-gap-14 {
        gap: 14px !important;
    }

    .sm-gap-16 {
        gap: 16px !important;
    }

    .sm-gap-18 {
        gap: 18px !important;
    }

    .sm-gap-20 {
        gap: 20px !important;
    }

    .sm-gap-22 {
        gap: 22px !important;
    }

    .sm-gap-24 {
        gap: 24px !important;
    }

    .sm-gap-26 {
        gap: 26px !important;
    }

    .sm-gap-28 {
        gap: 28px !important;
    }

    .sm-gap-30 {
        gap: 30px !important;
    }

    .sm-gap-32 {
        gap: 32px !important;
    }

    .sm-gap-34 {
        gap: 34px !important;
    }

    .sm-gap-36 {
        gap: 36px !important;
    }

    .sm-gap-38 {
        gap: 38px !important;
    }

    .sm-gap-40 {
        gap: 40px !important;
    }

    .sm-gap-42 {
        gap: 42px !important;
    }

    .sm-gap-44 {
        gap: 44px !important;
    }

    .sm-gap-46 {
        gap: 46px !important;
    }

    .sm-gap-48 {
        gap: 48px !important;
    }

    .sm-gap-50 {
        gap: 50px !important;
    }

    .sm-gap-52 {
        gap: 52px !important;
    }

    .sm-gap-54 {
        gap: 54px !important;
    }

    .sm-gap-56 {
        gap: 56px !important;
    }

    .sm-gap-58 {
        gap: 58px !important;
    }

    .sm-gap-60 {
        gap: 60px !important;
    }

    .sm-gap-62 {
        gap: 62px !important;
    }

    .sm-gap-64 {
        gap: 64px !important;
    }

    .sm-gap-66 {
        gap: 66px !important;
    }

    .sm-gap-68 {
        gap: 68px !important;
    }

    .sm-gap-70 {
        gap: 70px !important;
    }

    .sm-gap-72 {
        gap: 72px !important;
    }

    .sm-gap-74 {
        gap: 74px !important;
    }

    .sm-gap-76 {
        gap: 76px !important;
    }

    .sm-gap-78 {
        gap: 78px !important;
    }

    .sm-gap-80 {
        gap: 80px !important;
    }

    .sm-gap-82 {
        gap: 82px !important;
    }

    .sm-gap-84 {
        gap: 84px !important;
    }

    .sm-gap-86 {
        gap: 86px !important;
    }

    .sm-gap-88 {
        gap: 88px !important;
    }

    .sm-gap-90 {
        gap: 90px !important;
    }

    .sm-gap-92 {
        gap: 92px !important;
    }

    .sm-gap-94 {
        gap: 94px !important;
    }

    .sm-gap-96 {
        gap: 96px !important;
    }

    .sm-gap-98 {
        gap: 98px !important;
    }

    .sm-gap-100 {
        gap: 100px !important;
    }

    .sm-gap-102 {
        gap: 102px !important;
    }

    .sm-gap-104 {
        gap: 104px !important;
    }

    .sm-gap-106 {
        gap: 106px !important;
    }

    .sm-gap-108 {
        gap: 108px !important;
    }

    .sm-gap-110 {
        gap: 110px !important;
    }

    .sm-gap-112 {
        gap: 112px !important;
    }

    .sm-gap-114 {
        gap: 114px !important;
    }

    .sm-gap-116 {
        gap: 116px !important;
    }

    .sm-gap-118 {
        gap: 118px !important;
    }

    .sm-gap-120 {
        gap: 120px !important;
    }

    .sm-gap-122 {
        gap: 122px !important;
    }

    .sm-gap-124 {
        gap: 124px !important;
    }

    .sm-gap-126 {
        gap: 126px !important;
    }

    .sm-gap-128 {
        gap: 128px !important;
    }

    .sm-gap-130 {
        gap: 130px !important;
    }

    .sm-gap-132 {
        gap: 132px !important;
    }

    .sm-gap-134 {
        gap: 134px !important;
    }

    .sm-gap-136 {
        gap: 136px !important;
    }

    .sm-gap-138 {
        gap: 138px !important;
    }

    .sm-gap-140 {
        gap: 140px !important;
    }

    .sm-gap-142 {
        gap: 142px !important;
    }

    .sm-gap-144 {
        gap: 144px !important;
    }

    .sm-gap-146 {
        gap: 146px !important;
    }

    .sm-gap-148 {
        gap: 148px !important;
    }

    .sm-gap-150 {
        gap: 150px !important;
    }

    .sm-align-center {
        align-items: center !important;
    }

    .sm-justify-center {
        justify-content: center !important;
    }

    .sm-max-w-200 {
        max-width: 200px !important;
    }

    .sm-max-w-240 {
        max-width: 240px !important;
    }

    .sm-max-w-280 {
        max-width: 280px !important;
    }

    .sm-max-w-320 {
        max-width: 320px !important;
    }

    .sm-max-w-340 {
        max-width: 340px !important;
    }

    .sm-max-w-360 {
        max-width: 360px !important;
    }

    .sm-fs-48 {
        font-size: 48px !important;
    }

    .sm-fs-36 {
        font-size: 36px !important;
    }

    .sm-align-start {
        align-items: flex-start !important;
    }

    .sm-align-end {
        align-items: flex-end !important;
    }

    .sm-align-center {
        align-items: center !important;
    }

    .sm-text-center {
        text-align: center !important;
    }

    .sm-h-2 {
        height: 2px !important;
    }

    .sm-h-4 {
        height: 4px !important;
    }

    .sm-h-6 {
        height: 6px !important;
    }

    .sm-h-8 {
        height: 8px !important;
    }

    .sm-h-10 {
        height: 10px !important;
    }

    .sm-h-12 {
        height: 12px !important;
    }

    .sm-h-14 {
        height: 14px !important;
    }

    .sm-h-16 {
        height: 16px !important;
    }

    .sm-h-18 {
        height: 18px !important;
    }

    .sm-h-20 {
        height: 20px !important;
    }

    .sm-h-22 {
        height: 22px !important;
    }

    .sm-h-24 {
        height: 24px !important;
    }

    .sm-h-26 {
        height: 26px !important;
    }

    .sm-h-28 {
        height: 28px !important;
    }

    .sm-h-30 {
        height: 30px !important;
    }

    .sm-h-32 {
        height: 32px !important;
    }

    .sm-h-34 {
        height: 34px !important;
    }

    .sm-h-36 {
        height: 36px !important;
    }

    .sm-h-38 {
        height: 38px !important;
    }

    .sm-h-40 {
        height: 40px !important;
    }

    .sm-h-42 {
        height: 42px !important;
    }

    .sm-h-44 {
        height: 44px !important;
    }

    .sm-h-46 {
        height: 46px !important;
    }

    .sm-h-48 {
        height: 48px !important;
    }

    .sm-h-50 {
        height: 50px !important;
    }

    .sm-h-52 {
        height: 52px !important;
    }

    .sm-h-54 {
        height: 54px !important;
    }

    .sm-h-56 {
        height: 56px !important;
    }

    .sm-h-58 {
        height: 58px !important;
    }

    .sm-h-60 {
        height: 60px !important;
    }

    .sm-h-70 {
        height: 70px !important;
    }

    .sm-h-80 {
        height: 80px !important;
    }

    .sm-h-90 {
        height: 90px !important;
    }

    .sm-h-100 {
        height: 100px !important;
    }

    .sm-h-110 {
        height: 110px !important;
    }

    .sm-h-120 {
        height: 120px !important;
    }

    .sm-h-130 {
        height: 130px !important;
    }

    .sm-h-140 {
        height: 140px !important;
    }

    .sm-h-150 {
        height: 150px !important;
    }

    .sm-h-160 {
        height: 160px !important;
    }

    .sm-h-170 {
        height: 170px !important;
    }

    .sm-h-180 {
        height: 180px !important;
    }

    .sm-h-190 {
        height: 190px !important;
    }

    .sm-h-200 {
        height: 200px !important;
    }

    .sm-h-210 {
        height: 210px !important;
    }

    .sm-h-220 {
        height: 220px !important;
    }

    .sm-h-230 {
        height: 230px !important;
    }

    .sm-h-240 {
        height: 240px !important;
    }

    .sm-h-250 {
        height: 250px !important;
    }

    .sm-h-260 {
        height: 260px !important;
    }

    .sm-h-270 {
        height: 270px !important;
    }

    .sm-h-280 {
        height: 280px !important;
    }

    .sm-line-h-1 {
        line-height: 10px !important;
    }

    .sm-line-h-2 {
        line-height: 20px !important;
    }

    .sm-line-h-3 {
        line-height: 30px !important;
    }

    .sm-line-h-4 {
        line-height: 40px !important;
    }

    .sm-line-h-5 {
        line-height: 50px !important;
    }

    .sm-line-h-6 {
        line-height: 60px !important;
    }

    .sm-line-h-7 {
        line-height: 70px !important;
    }

    .sm-line-h-8 {
        line-height: 80px !important;
    }

    .sm-min-w-fit {
        min-width: fit-content !important;
    }

    .sm-w-auto {
        width: auto !important;
    }

    .sm-min-w-full {
        min-width: 100% !important;
    }

    .sm-min-w-100 {
        min-width: 100px !important;
    }

    .sm-min-w-120 {
        min-width: 120px !important;
    }

    .sm-min-w-130 {
        min-width: 130px !important;
    }

    .sm-min-w-140 {
        min-width: 140px !important;
    }

    .sm-min-w-150 {
        min-width: 150px !important;
    }

    .sm-min-w-200 {
        min-width: 200px !important;
    }

    .sm-min-w-240 {
        min-width: 240px !important;
    }

    .sm-min-w-260 {
        min-width: 260px !important;
    }

    .sm-min-w-280 {
        min-width: 280px !important;
    }

    .sm-min-w-300 {
        min-width: 300px !important;
    }

    .sm-min-w-320 {
        min-width: 320px !important;
    }

    .sm-min-w-340 {
        min-width: 340px !important;
    }

    .sm-min-w-360 {
        min-width: 360px !important;
    }

    .sm-min-w-380 {
        min-width: 380px !important;
    }

    .sm-max-w-full {
        max-width: 100% !important;
    }

    .sm-w-full {
        width: 100% !important;
    }

    .sm-max-w-240 {
        max-width: 240px !important;
    }

    .sm-max-w-260 {
        max-width: 260px !important;
    }

    .sm-max-w-280 {
        max-width: 280px !important;
    }

    .sm-max-w-300 {
        max-width: 300px !important;
    }

    .sm-max-w-320 {
        max-width: 320px !important;
    }

    .sm-max-w-340 {
        max-width: 340px !important;
    }

    .sm-max-w-360 {
        max-width: 360px !important;
    }

    .sm-max-w-fit {
        max-width: fit-content !important;
    }

    .sm-overflow-scroll {
        overflow: scroll !important;
    }

    .sm-flex-nowrap {
        flex-wrap: nowrap !important;
    }

    .sm-w-min-fit {
        min-width: fit-content !important;
    }

    .sm-w-fit {
        width: fit-content !important;
    }

    .sm-d-flex {
        display: flex !important;
    }

    .sm-d-none {
        display: none !important;
    }

    .sm-p-b-10 {
        padding-bottom: 10px !important;
    }

    .sm-p-b-20 {
        padding-bottom: 20px !important;
    }

    .sm-p-b-30 {
        padding-bottom: 30px !important;
    }

    .sm-p-b-40 {
        padding-bottom: 40px !important;
    }

    .sm-p-b-50 {
        padding-bottom: 50px !important;
    }

    .sm-grid-colt-1 {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .sm-grid-colt-2 {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .sm-grid-colt-3 {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .sm-grid-colt-4 {
        grid-template-columns: repeat(4, 1fr) !important;
    }
}

@media (min-width:1044px) {
    .d-none-sm {
        display: none !important;
    }
}

@media (max-width:768px) {

    .xs-grid-colt-1 {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .xs-grid-colt-2 {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .xs-grid-colt-3 {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .xs-grid-colt-4 {
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .xsm-max-w-100 {
        max-width: 100px !important;
    }

    .xsm-flex-col {
        flex-direction: column !important;
    }

    .xsm-d-none {
        display: none !important;
    }

    .xsm-overflow-scroll {
        overflow: scroll !important;
    }

    .xsm-w-fit {
        width: fit-content !important;
    }

    .xsm-flex-nowrap {
        flex-wrap: nowrap !important;
    }
}

@media (min-width:786px) {
    .d-none-xsm {
        display: none !important;
    }
}

@media (max-width:540px) {

    .xxs-grid-colt-1 {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .xxs-grid-colt-2 {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .xxs-grid-colt-3 {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .xxs-grid-colt-4 {
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .xxsm-max-w-100 {
        max-width: 100px !important;
    }

    .xxsm-fs-24 {
        font-size: 24px !important;
    }

    .xxsm-fs-36 {
        font-size: 36px !important;
    }

    .xxsm-flex-col {
        flex-direction: column !important;
    }

    .xxsm-d-flex {
        display: flex !important;
    }

    .xxsm-border-0 {
        border: 0 !important;
    }

    .xxsm-max-w-200 {
        max-width: 200px !important;
    }

    .xxsm-max-w-210 {
        max-width: 210px !important;
    }

    .xxsm-max-w-220 {
        max-width: 220px !important;
    }

    .xxsm-max-w-230 {
        max-width: 230px !important;
    }

    .xxsm-max-w-240 {
        max-width: 240px !important;
    }

    .xxsm-max-w-250 {
        max-width: 250px !important;
    }

    .xxsm-max-w-260 {
        max-width: 260px !important;
    }

    .xxsm-max-w-270 {
        max-width: 270px !important;
    }

    .xxsm-max-w-280 {
        max-width: 280px !important;
    }

    .xxsm-max-w-290 {
        max-width: 290px !important;
    }

    .xxsm-max-w-300 {
        max-width: 300px !important;
    }
}

.text-end {
    text-align: end !important;
}
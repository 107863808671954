.address__card {
    display: flex;
    flex-direction: column;
    gap: 10px;

    border-radius: 10px;
    padding: 16px;
    border: 1px solid #00502d;

    .card__heading {
        color: var(--text-color, #18303E);
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
    }

    .card__li {
        color: var(--text-color, #18303E);
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        width: 300px;
        gap: 8px;

        >span {
            color: var(--primary-color);
        }
    }
}

.address__card.active {
    border: 2px solid var(--primary-color, #BF3939);
}
.product__cards {
    display: grid;
    gap: 32px;

    .product__card {
        border: 1px solid #eee;

        .price__span {
            transition: .3s;
        }

        .product__name {
            text-align: center;
            opacity: 1;
            transition: .3s;
        }

        .icon__container {
            overflow: hidden;
            transition: .3s;
            width: 0;
            height: 0;
            opacity: 0;
        }

        .img {
            height: 180px;
            overflow: hidden;

            >img {
                height: 100%;
            }
        }
    }
}
.search__nav {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .search__bar {
        grid-column: 1/6;

        height: 100%;
        border: 1px solid #eee;

        .category {
            border-right: 1px solid #eee;
        }

        .search__field {
            >input {
                outline: none;
                border: 0;
            }

            .search__btn {
                padding: 0 32px;
                outline: none;

                border: 0;
                height: 100%;
            }
        }
    }

    /* .customDropdown .ant-select-selector {
        border: none !important;
    } */
}

@media (max-width:1040px) {
    .search__nav{
        .search__bar{
            gap: 10px;
            
            .category{
                width: 100%;
                border-bottom: 1px solid #eee;
            }
        }
    }
}